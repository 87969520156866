import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useContractsStore = defineStore('contracts', {
	state: () => ({
		contracts: [],
	}),
	actions: {
		async getContracts() {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts?customer=1';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					that.contracts = response.data;
				});

			return this.contracts;
		},
		async getContract(contractId) {
			let contract = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts/' +
				contractId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						address: 1,
						customer: 1,
						contractpositions: 1,
					},
				})
				.then((response) => {
					contract = response.data;
				});

			return contract;
		},
		async getContractDocuments(contractId) {
			let documents = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts/' +
				contractId +
				'/documents';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					documents = response.data;
				});

			return documents;
		},
		async addContractDocument({ contractId, documentData }) {
			let document = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts/' +
				contractId +
				'/documents';

			await axios
				.post(url, documentData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					document = response.data;
				});

			return document;
		},
		async addContract(contractData) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let contract = null;

			const url =
				managerpanel_accountingmanagement_backend_url + 'contracts';
			await axios
				.post(url, contractData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					contract = response.data;
				});

			return contract;
		},
		async editContract({ contractId, contractData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let contract = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts/' +
				contractId;
			await axios
				.put(url, contractData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					contract = response.data;
				});

			return contract;
		},
		async addContractPosition({ positionData, contractId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts/' +
				contractId +
				'/contractpositions';
			await axios
				.post(url, positionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async editContractPosition({ positionData, contractId, positionId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts/' +
				contractId +
				'/contractpositions/' +
				positionId;
			await axios
				.put(url, positionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async deleteContractPosition({ contractId, positionId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'contracts/' +
				contractId +
				'/contractpositions/' +
				positionId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
	},
});
