import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/Home.vue';
import ReceiptsIndex from '@/views/Receipts/index.vue';
import ReceiptsView from '@/views/Receipts/view.vue';
import ReceiptsAdd from '@/views/Receipts/add.vue';
import ReceiptsEdit from '@/views/Receipts/edit.vue';
import ReceiptsNotAssigned from '@/views/Receipts/notAssigned.vue';
import ReceiptsStatistics from '@/views/Receipts/statistics.vue';
import CustomersIndex from '@/views/Customers/index.vue';
import CustomerView from '@/views/Customers/view.vue';
import CustomerAdd from '@/views/Customers/add.vue';
import CustomerEdit from '@/views/Customers/edit.vue';
import Invoices from '@/views/Invoices/index.vue';
import InvoicesView from '@/views/Invoices/view.vue';
import InvoicesEdit from '@/views/Invoices/edit.vue';
import AccountsIndex from '@/views/Accounts/index.vue';
import AccountsAdd from '@/views/Accounts/add.vue';
import AccountsEdit from '@/views/Accounts/edit.vue';
import AccountsView from '@/views/Accounts/view.vue';
import TransactionsAdd from '@/views/Transactions/add.vue';
import TransactionsView from '@/views/Transactions/view.vue';
import TransactionsEdit from '@/views/Transactions/edit.vue';
import ArticlesIndex from '@/views/Articles/index.vue';
import ArticlesView from '@/views/Articles/view.vue';
import ArticlesAdd from '@/views/Articles/add.vue';
import ArticlesEdit from '@/views/Articles/edit.vue';
import CostestimatesIndex from '@/views/Costestimates/index.vue';
import CostestimatesAdd from '@/views/Costestimates/add.vue';
import CostestimatesView from '@/views/Costestimates/view.vue';
import CostestimatesEdit from '@/views/Costestimates/edit.vue';
import AssignmentsIndex from '@/views/Assignments/index.vue';
import AssignmentsAdd from '@/views/Assignments/add.vue';
import AssignmentsView from '@/views/Assignments/view.vue';
import AssignmentsEdit from '@/views/Assignments/edit.vue';
import ContractsIndex from '@/views/Contracts/index.vue';
import ContractsView from '@/views/Contracts/view.vue';
import ContractsAdd from '@/views/Contracts/add.vue';
import ContractsEdit from '@/views/Contracts/edit.vue';
import Export from '@/views/Export.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/export',
		name: 'Export',
		component: Export,
	},
	{
		path: '/receipts',
		name: 'ReceiptsIndex',
		component: ReceiptsIndex,
	},
	{
		path: '/receipts/statistics',
		name: 'ReceiptsStatistics',
		component: ReceiptsStatistics,
	},
	{
		path: '/receipts/notAssigned',
		name: 'ReceiptsNotAssigned',
		component: ReceiptsNotAssigned,
	},
	{
		path: '/receipts/:manufactorId/:id',
		name: 'ReceiptsView',
		component: ReceiptsView,
	},
	{
		path: '/receipts/add',
		name: 'ReceiptsAdd',
		component: ReceiptsAdd,
	},
	{
		path: '/receipts/edit/:manufactorId/:id',
		name: 'ReceiptsEdit',
		component: ReceiptsEdit,
	},
	{
		path: '/customers',
		name: 'Customers',
		component: CustomersIndex,
	},
	{
		path: '/customers/:id',
		name: 'CustomersView',
		component: CustomerView,
	},
	{
		path: '/customers/add',
		name: 'CustomersAdd',
		component: CustomerAdd,
	},
	{
		path: '/customers/edit/:id',
		name: 'CustomersEdit',
		component: CustomerEdit,
	},
	{
		path: '/invoices',
		name: 'Invoices',
		component: Invoices,
	},
	{
		path: '/invoices/:id',
		name: 'InvoicesView',
		component: InvoicesView,
	},
	{
		path: '/invoices/edit/:id',
		name: 'InvoicesEdit',
		component: InvoicesEdit,
	},
	{
		path: '/accounts',
		name: 'AccountsIndex',
		component: AccountsIndex,
	},
	{
		path: '/accounts/:id',
		name: 'AccountsView',
		component: AccountsView,
	},
	{
		path: '/accounts/add',
		name: 'AccountsAdd',
		component: AccountsAdd,
	},
	{
		path: '/accounts/edit/:id',
		name: 'AccountsEdit',
		component: AccountsEdit,
	},
	{
		path: '/accounts/:account_id/transactions/:id',
		name: 'TransactionsView',
		component: TransactionsView,
	},
	{
		path: '/accounts/:id/transactions/add',
		name: 'TransactionsAdd',
		component: TransactionsAdd,
	},
	{
		path: '/accounts/:account_id/transactions/edit/:id',
		name: 'TransactionsEdit',
		component: TransactionsEdit,
	},
	{
		path: '/articles',
		name: 'ArticlesIndex',
		component: ArticlesIndex,
	},
	{
		path: '/articles/:id',
		name: 'ArticlesView',
		component: ArticlesView,
	},
	{
		path: '/articles/add',
		name: 'ArticlesAdd',
		component: ArticlesAdd,
	},
	{
		path: '/articles/edit/:id',
		name: 'ArticlesEdit',
		component: ArticlesEdit,
	},
	{
		path: '/costestimates',
		name: 'CostestimatesIndex',
		component: CostestimatesIndex,
	},
	{
		path: '/costestimates/:id',
		name: 'CostestimatesView',
		component: CostestimatesView,
	},
	{
		path: '/costestimates/add',
		name: 'CostestimatesAdd',
		component: CostestimatesAdd,
	},
	{
		path: '/costestimates/edit/:id',
		name: 'CostestimatesEdit',
		component: CostestimatesEdit,
	},
	{
		path: '/assignments',
		name: 'AssignmentsIndex',
		component: AssignmentsIndex,
	},
	{
		path: '/assignments/add',
		name: 'AssignmentsAdd',
		component: AssignmentsAdd,
	},
	{
		path: '/assignments/:id',
		name: 'AssignmentsView',
		component: AssignmentsView,
	},
	{
		path: '/assignments/edit/:id',
		name: 'AssignmentsEdit',
		component: AssignmentsEdit,
	},
	{
		path: '/contracts',
		name: 'ContractsIndex',
		component: ContractsIndex,
	},
	{
		path: '/contracts/:id',
		name: 'ContractsView',
		component: ContractsView,
	},
	{
		path: '/contracts/add',
		name: 'ContractsAdd',
		component: ContractsAdd,
	},
	{
		path: '/contracts/edit/:id',
		name: 'ContractsEdit',
		component: ContractsEdit,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
