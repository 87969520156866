import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useCustomersStore = defineStore('customers', {
	state: () => ({
		customers: [],
	}),
	actions: {
		async getCustomers() {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url + 'customers';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					that.customers = response.data;
				});

			return this.customers;
		},
		async getCustomer(customerId) {
			let customer = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId;

			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						addresses: 1,
						mails: 1,
						phonenumbers: 1,
						invoices: 1,
					},
				})
				.then((response) => {
					customer = response.data;
				});

			return customer;
		},
		async addCustomer(customerData) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let customer = null;

			const url =
				managerpanel_accountingmanagement_backend_url + 'customers';

			await axios
				.post(url, customerData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					customer = response.data;
				});

			return customer;
		},
		async editCustomer({ customerId, customerData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let customer = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId;

			// prepare transfer data
			const transferData = {
				number: customerData.number,
				type: customerData.type,
				salutation: customerData.salutation,
				firstname: customerData.firstname,
				lastname: customerData.lastname,
				organisation: customerData.organisation,
				position: customerData.position,
				taxnumber: customerData.taxnumber,
				ustid: customerData.ustid,
			};

			await axios
				.put(url, transferData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					customer = response.data;
				});

			return customer;
		},
		async addCustomerAddresse({ addresseData, customerId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/addresses';

			await axios
				.post(url, addresseData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async editCustomerAddresse({ addresseData, customerId, addresseId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/addresses/' +
				addresseId;
			await axios
				.put(url, addresseData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async deleteCustomerAddresse({ customerId, addresseId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/addresses/' +
				addresseId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async addCustomerPhonenumber({ phonenumberData, customerId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/phonenumbers';

			await axios
				.post(url, phonenumberData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async editCustomerPhonenumber({
			phonenumberData,
			customerId,
			phonenumberId,
		}) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/phonenumbers/' +
				phonenumberId;
			await axios
				.put(url, phonenumberData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async deleteCustomerPhonenumber({ customerId, phonenumberId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/phonenumbers/' +
				phonenumberId;

			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async addCustomerMail({ mailData, customerId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/mails';

			await axios
				.post(url, mailData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async editCustomerMail({ mailData, customerId, mailId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/mails/' +
				mailId;
			await axios
				.put(url, mailData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async deleteCustomerMail({ customerId, mailId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'customers/' +
				customerId +
				'/mails/' +
				mailId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
	},
});
