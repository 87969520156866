<template>
	<router-link to="/costestimates/add">Add</router-link>

	<w-datatable
		:headers="headers"
		:items="costestimates"
		:actions="actions"
		:showedColumns="showedColumns"
		@view="handleCostestimateView"
		@edit="handleCostestimateEdit"
	/>
</template>

<script>
	import { WDatatable } from '@wirecore/wirecomponents';
	import { useCostestimatesStore } from './../../store/costestimates';

	export default {
		name: 'CostestimatesIndex',
		components: {
			WDatatable,
		},
		methods: {
			showDialog() {
				this.$refs.dialog.show();
			},
			handleCostestimateView(data) {
				this.$router.push({
					name: 'CostestimatesView',
					params: { id: data.id },
				});
			},
			handleCostestimateEdit(data) {
				this.$router.push({
					name: 'CostestimatesEdit',
					params: { id: data.id },
				});
			},
		},
		mounted() {
			this.$nextTick(function () {
				const costestimatesStore = useCostestimatesStore();
				costestimatesStore.getCostestimates().then((response) => {
					response.forEach(function (value) {
						if (value.customer_type == 'b2b') {
							value.customer = value.customer_organisation;
						} else {
							value.customer =
								value.customer_firstname +
								' ' +
								value.customer_lastname;
						}
					});

					this.costestimates = response;
				});
			});
		},
		data() {
			return {
				dialogActive: false,
				showedColumns: [
					'status',
					'number',
					'customer',
					'title',
					'price',
				],
				actions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
				],
				headers: [
					{
						text: 'Status',
						value: 'status',
					},
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Customer',
						value: 'customer',
					},
					{
						text: 'Title',
						value: 'title',
					},
					{
						text: 'Price',
						value: 'price',
					},
				],
				costestimates: [],
			};
		},
	};
</script>

<style></style>
