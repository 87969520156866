<template>
	<router-link to="/accounts/add">Add</router-link>

	<w-datatable
		:headers="headers"
		:items="accounts"
		:actions="actions"
		:showedColumns="showedColumns"
		@view="handleAccountView"
		@edit="handleAccountEdit"
	/>
</template>

<script>
	import { WDatatable } from '@wirecore/wirecomponents';
	import { useAccountsStore } from './../../store/accounts';

	export default {
		name: 'AccountsIndex',
		components: {
			WDatatable,
		},
		methods: {
			showDialog() {
				this.$refs.dialog.show();
			},
			handleAccountView(data) {
				this.$router.push({
					name: 'AccountsView',
					params: { id: data.id },
				});
			},
			handleAccountEdit(data) {
				this.$router.push({
					name: 'AccountsEdit',
					params: { id: data.id },
				});
			},
		},
		mounted() {
			this.$nextTick(function () {
				const accountsStore = useAccountsStore();
				accountsStore.getAccounts().then((response) => {
					this.accounts = response;
				});
			});
		},
		data() {
			return {
				dialogActive: false,
				showedColumns: ['bankname', 'amount', 'iban', 'bic'],
				actions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
				],
				headers: [
					{
						text: 'Bankname',
						value: 'bankname',
					},
					{
						text: 'Amount',
						value: 'amount',
					},
					{
						text: 'IBAN',
						value: 'iban',
					},
					{
						text: 'BIC',
						value: 'bic',
					},
				],
				accounts: [],
			};
		},
	};
</script>

<style></style>
