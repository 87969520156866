<template>
	<p>Articles View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="article.number" type="text" disabled
						>Articlenumber</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="article.title" type="text" disabled
						>Title</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="article.ek" type="text" disabled
						>EK</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="article.vat" type="text" disabled
						>Vat</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="article.manufactor" type="text" disabled
						>Manufactor</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="article.vk" type="text" disabled
						>VK</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-textarea
						v-model="article.description"
						type="text"
						disabled
						>Description</w-textarea
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-textarea
						v-model="article.supplierinfo"
						type="text"
						disabled
						>Supplierinfo</w-textarea
					>
				</div>
			</div>
		</div>
		<w-card>
			<div class="flex justify-evenly">
				<w-checkbox v-model="article.active" disabled
					>Active</w-checkbox
				>
				<w-checkbox v-model="article.camparticle" disabled
					>Camparticle</w-checkbox
				>
				<w-checkbox v-model="article.service" disabled
					>Service</w-checkbox
				>
				<w-checkbox v-model="article.toproduction" disabled
					>To Production</w-checkbox
				>
				<w-checkbox v-model="article.saleitem" disabled
					>Saleitem</w-checkbox
				>
			</div>
		</w-card>
	</div>
</template>

<script>
	import {
		WInput,
		WTextarea,
		WCheckbox,
		WCard,
	} from '@wirecore/wirecomponents';
	import { useArticlesStore } from './../../store/articles';

	export default {
		name: 'ArticlesView',
		components: {
			WInput,
			WTextarea,
			WCheckbox,
			WCard,
		},
		data() {
			return {
				article: {
					id: '',
					number: '',
					active: '',
					description: '',
					vk: '',
					ek: '',
					vat: '',
					manufactor: '',
					camparticle: '',
					saleitem: '',
					service: '',
					supplierinfo: '',
					toproduction: '',
				},
			};
		},
		mounted() {
			const articleId = this.$route.params.id;

			const articlesStore = useArticlesStore();
			articlesStore.getArticle(articleId).then((response) => {
				this.article = response;
			});
		},
	};
</script>

<style></style>
