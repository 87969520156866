<template>
	<p>Accounts View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="account.bankname" type="text" disabled
						>Bankname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="account.iban" type="text" disabled
						>IBAN</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="account.bic" type="text" disabled
						>BIC</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="account.owner" type="text" disabled
						>Owner</w-input
					>
				</div>
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'N26', value: 'n26' },
							{ name: 'Commerzbank', value: 'commerzbank' },
						]"
						v-model="account.banking_institution"
						disabled
						>Banking Institution</w-select
					>
				</div>
				<div class="mb-4">
					<w-checkbox v-model="account.default_used" disabled
						>Standart</w-checkbox
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<p>Aktueller Kontostand: {{ getCurrentAccountBalance }}</p>
	</div>
	<div class="container mx-auto">
		<LineChart :chartData="linechartData" />
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-btn @click="handleTransactionRedirect">Transaction Add</w-btn>
			<w-btn @click="openImportTransactionsDialog"
				>Transaction Import</w-btn
			>
			<w-dialog ref="transactionImportDialog" @hide="handleDialogHide()">
				<input
					class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					type="file"
					@change="transactionCSVHandler"
					:value="transactionCSV"
				/>
				<w-btn @click="sendImportTransactionsCSV">Importieren</w-btn>
				<div v-if="transactionsImportDuplicated != null">
					<p style="color: white">
						Duplikate: {{ this.transactionsImportDuplicated }}
					</p>
					<p style="color: white">
						Importiert: {{ this.transactionsImportImported }}
					</p>
				</div>
			</w-dialog>
		</div>
	</div>
	<w-datatable
		:headers="transactionHeaders"
		:items="account.Transactions"
		:actions="transactionActions"
		:showedColumns="transactionShowedColumns"
		@view="handleTransactionView"
		@edit="handleTransactionEdit"
		@delete="handleTransactionDelete"
		items_per_page="50"
	/>
</template>

<script>
	import { Chart, registerables } from 'chart.js';
	Chart.register(...registerables);
	import { LineChart } from 'vue-chart-3';
	import moment from 'moment';
	import { useAccountsStore } from './../../store/accounts';
	import { useTransactionsStore } from './../../store/transactions';

	import {
		WInput,
		WCheckbox,
		WBtn,
		WSelect,
		WDialog,
		WDatatable,
	} from '@wirecore/wirecomponents';

	export default {
		name: 'AccountsView',
		components: {
			LineChart,
			WDialog,
			WInput,
			WBtn,
			WCheckbox,
			WSelect,
			WDatatable,
		},
		computed: {
			getCurrentAccountBalance() {
				let balance = 0;

				this.account.Transactions.forEach(function (value) {
					balance += value.amount;
				});

				balance = balance.toLocaleString('de-DE', {
					style: 'currency',
					currency: 'EUR',
				});

				return balance;
			},
		},
		data() {
			return {
				linechartData: {
					labels: [],
					datasets: [
						{
							data: [],
							backgroundColor: ['#77CEFF'],
						},
					],
				},
				accountId: null,
				account: {
					bankname: '',
					iban: '',
					bic: '',
					owner: '',
					default_used: '',
					Transactions: [],
				},
				transactionShowedColumns: ['booking', 'informations', 'amount'],
				transactionActions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
					{
						text: 'Delete',
						emitName: 'delete',
					},
				],
				transactionHeaders: [
					{
						text: 'Booking',
						value: 'booking',
					},
					{
						text: 'Informations',
						value: 'informations',
					},
					{
						text: 'Amount',
						value: 'amount',
					},
				],
				transactionCSV: '',
				transactionsCSVBase64: null,
				transactionsImportDuplicated: null,
				transactionsImportImported: null,
			};
		},
		mounted() {
			this.$nextTick(function () {
				const accountId = this.$route.params.id;
				this.accountId = accountId;

				const accountsStore = useAccountsStore();
				accountsStore.getAccount(accountId).then((response) => {
					this.account = response;

					let transactionMonthData = [];

					// find oldest timestamp to find last month
					let oldestTimestamp = null;
					this.account.Transactions.forEach(function (value) {
						if (
							oldestTimestamp == null ||
							moment(value.booking).unix() < oldestTimestamp
						) {
							oldestTimestamp = moment(value.booking).unix();
						}
					});

					// prepare all transactions months there exists
					const monthDifferenceToCurrentDate = moment().diff(
						moment.unix(oldestTimestamp),
						'months',
						true
					);
					for (let i = monthDifferenceToCurrentDate; i > 0; i--) {
						let arrKey = moment()
							.subtract(i, 'months')
							.format('YYYY_MM');
						transactionMonthData[arrKey] = {
							dateKey: arrKey,
							amount: 0,
						};
					}

					// calculate transactions together to months
					this.account.Transactions.forEach(function (value) {
						if (
							transactionMonthData[
								moment(value.booking).format('YYYY_MM')
							] != undefined
						) {
							transactionMonthData[
								moment(value.booking).format('YYYY_MM')
							].amount += parseFloat(value.amount);
						}
					});

					// calculate all months together
					let arrLastValue = 0;
					Object.values(transactionMonthData).forEach(
						function (value) {
							transactionMonthData[value.dateKey].amount +=
								arrLastValue;
							arrLastValue = value.amount;
						}
					);

					// prepare chart data for last 12 months
					let chartData = [];
					let chartLabels = [];
					let endLabel = '';
					let startLabel = '';

					transactionMonthData = Object.values(transactionMonthData);
					for (let i = 1; i < 13; i++) {
						if (
							transactionMonthData[
								transactionMonthData.length - i
							] !== undefined
						) {
							let amount = parseInt(
								transactionMonthData[
									transactionMonthData.length - i
								].amount
							);
							chartData.push(amount);
							let label = moment(
								transactionMonthData[
									transactionMonthData.length - i
								].dateKey.replace('_', '-') + '-01'
							).format('MMMM YYYY');
							chartLabels.push(label);
							if (i == 1) {
								endLabel = moment(
									transactionMonthData[
										transactionMonthData.length - i
									].dateKey.replace('_', '-') + '-01'
								).format('MMMM YYYY');
							}
							if (i == 12) {
								startLabel = moment(
									transactionMonthData[
										transactionMonthData.length - i
									].dateKey.replace('_', '-') + '-01'
								).format('MMMM YYYY');
							}
						}
					}

					this.linechartData.labels = chartLabels.reverse();
					this.linechartData.datasets[0].data = chartData.reverse();
					this.linechartData.datasets[0].fill = true;
					this.linechartData.datasets[0].label =
						startLabel + ' - ' + endLabel;
				});
			});
		},
		methods: {
			handleTransactionRedirect() {
				this.$router.push({
					name: 'TransactionsAdd',
					params: { id: this.accountId },
				});
			},
			handleTransactionView(data) {
				this.$router.push({
					name: 'TransactionsView',
					params: { id: data.id, account_id: data.account_id },
				});
			},
			handleTransactionEdit(data) {
				this.$router.push({
					name: 'TransactionsEdit',
					params: { id: data.id, account_id: data.account_id },
				});
			},
			handleTransactionDelete(data) {
				let searchedIndex = null;
				this.account.Transactions.forEach(function (value, index) {
					if (value.id == data.id) {
						searchedIndex = index;
					}
				});

				const transactionsStore = useTransactionsStore();
				transactionsStore
					.deleteTransaction({
						accountId: data.account_id,
						transactionId: data.id,
					})
					.then(() => {
						this.account.Transactions.splice(searchedIndex, 1);
					});
			},
			openImportTransactionsDialog() {
				this.$refs.transactionImportDialog.show();
			},
			transactionCSVHandler(event) {
				//Read File
				var selectedFile = event.target.files;
				//Check File is not Empty
				if (selectedFile.length > 0) {
					// Select the very first file from list
					var fileToLoad = selectedFile[0];
					// FileReader function for read the file.
					var fileReader = new FileReader();
					var base64;
					// Onload of file read the file content
					let that = this;
					fileReader.onload = function (fileLoadedEvent) {
						base64 = fileLoadedEvent.target.result;
						// Print data in console
						that.transactionsCSVBase64 = base64;
					};
					// Convert data to base64
					fileReader.readAsDataURL(fileToLoad);
				}
			},
			sendImportTransactionsCSV() {
				const transactionsStore = useTransactionsStore();
				transactionsStore
					.importTransactionsCSV({
						csvData: this.transactionsCSVBase64,
						accountId: this.accountId,
					})
					.then((response) => {
						this.transactionsImportDuplicated =
							response.duplicates.length;
						this.transactionsImportImported =
							response.imported.length;
					});
			},
			handleDialogHide() {
				this.transactionsImportDuplicated = null;
				this.transactionsImportImported = null;
			},
		},
	};
</script>

<style></style>
