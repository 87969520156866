<template>
	<p>Contracts Add</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.title" type="text"
						>Title</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker format="YYYY-MM-DD" v-model="contract.begin"
						>Vertragsbeginn</w-datepicker
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.noticeperiodcount" type="text"
						>Kündigungsfrist Tage</w-input
					>
					<w-select
						:options="[
							{ name: 'Tage', value: 'daily' },
							{ name: 'Wochen', value: 'weekly' },
							{ name: 'Monate', value: 'monthly' },
							{ name: 'Quatale', value: 'quatal' },
							{ name: 'Jahre', value: 'yearly' },
						]"
						v-model="contract.noticeperiodcycle"
						>Kündigungsfrist</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.autorenewalcount" type="text"
						>Automatische Verlängerung Tage</w-input
					>
					<w-select
						:options="[
							{ name: 'Tage', value: 'daily' },
							{ name: 'Wochen', value: 'weekly' },
							{ name: 'Monate', value: 'monthly' },
							{ name: 'Quatale', value: 'quatal' },
							{ name: 'Jahre', value: 'yearly' },
						]"
						v-model="contract.autorenewalcycle"
						>Automatische Verlängerung</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.mintermcount" type="text"
						>Mindestlaufzeit Tage</w-input
					>
					<w-select
						:options="[
							{ name: 'Tage', value: 'daily' },
							{ name: 'Wochen', value: 'weekly' },
							{ name: 'Monate', value: 'monthly' },
							{ name: 'Quatale', value: 'quatal' },
							{ name: 'Jahre', value: 'yearly' },
						]"
						v-model="contract.mintermcycle"
						>Mindestlaufzeit Zyklus</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-checkbox v-model="contract.permanent"
						>Unbefristet</w-checkbox
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-btn @click="this.openCustomerDialog()"
						>Select Customer</w-btn
					>
					<w-dialog ref="customerDialog">
						<w-datatable
							:headers="customerHeaders"
							:items="customers"
							:actions="customerActions"
							:showedColumns="customerShowedColumns"
							@setCustomer="handleCustomerSelect"
						/>
					</w-dialog>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.type" type="text"
						>Customer Type</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.salutation" type="text"
						>Customer Salutation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.firstname" type="text"
						>Customer Firstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.lastname" type="text"
						>Customer Lastname</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="selectedCustomer.organisation" type="text"
						>Customerorganisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.street" type="text"
						>Customer Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.plz" type="text"
						>Customer PLZ</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.city" type="text"
						>Customer City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.country" type="text"
						>Customer Country</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="m-4">
			<w-btn @click="this.openPositionDialog()">Add Position</w-btn>
		</div>
		<w-dialog ref="positionDialog">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="positionDialog.articlename" type="text"
						>Article</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.count" type="text"
						>Count</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.price" type="text"
						>VK</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.ek" type="text"
						>EK</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.vat" type="text"
						>Vat</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.discount" type="text"
						>Discount</w-input
					>
				</div>
				<w-btn @click="savePosition()">Save</w-btn>
				<w-btn
					v-if="positionDialogShowDelete == true"
					@click="deletePosition()"
					>Delete</w-btn
				>
			</div>
		</w-dialog>
		<w-table
			:headers="[
				{ text: 'Title', value: 'articlename' },
				{ text: 'Count', value: 'count' },
				{ text: 'VK', value: 'price' },
				{ text: 'EK', value: 'ek' },
				{ text: 'Vat', value: 'vat' },
				{ text: 'Discount', value: 'discount' },
				{ text: 'Positionswert (Netto)', value: 'netto' },
				{ text: 'Action' },
			]"
			:items="contractPositions"
			@rowclick="handleRowClick"
		>
			<template #actions>
				<a
					href="#"
					class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
					>Edit / Delete</a
				>
			</template>
		</w-table>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.number" type="text" disabled
						>Number</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="contract.conclusion"
						>Vertragsabschluss</w-datepicker
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Aktiv', value: 'active' },
							{ name: 'Inaktiv', value: 'inactive' },
						]"
						v-model="contract.status"
						>Status</w-select
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[{ name: 'Überweisung', value: 'invoice' }]"
						v-model="contract.paymentmethod"
						>Paymentmethod</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.settlement" type="text"
						>Abrechnungstag</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Täglich', value: 'daily' },
							{ name: 'Wöchentlich', value: 'weekly' },
							{ name: 'Monatlich', value: 'monthly' },
							{ name: 'Quatal', value: 'quatal' },
							{ name: 'Jährlich', value: 'yearly' },
						]"
						v-model="contract.settlementcycle"
						>Abrechnungszyklus</w-select
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea v-model="contract.comments">Comments</w-textarea>
		</div>
	</div>
	<w-btn @click="this.addContract()">Hinzufügen</w-btn>
</template>

<script>
	import {
		WInput,
		WBtn,
		WTable,
		WSelect,
		WTextarea,
		WDatepicker,
		WCheckbox,
		WDatatable,
		WDialog,
	} from '@wirecore/wirecomponents';
	import { useContractsStore } from './../../store/contracts';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'ContractsAdd',
		components: {
			WDialog,
			WDatatable,
			WBtn,
			WInput,
			WTable,
			WSelect,
			WTextarea,
			WDatepicker,
			WCheckbox,
		},
		mounted() {
			this.$nextTick(function () {
				const customersStore = useCustomersStore();
				customersStore.getCustomers().then((response) => {
					this.customers = response;
				});
			});
		},
		data() {
			return {
				customerShowedColumns: ['number', 'name', 'organisation'],
				customerActions: [
					{
						text: 'Select',
						emitName: 'setCustomer',
					},
				],
				customerHeaders: [
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Name',
						value: 'lastname',
					},
					{
						text: 'organisation',
						value: 'organisation',
					},
				],
				customers: [],
				selectedCustomer: {
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					organisation: '',
				},
				selectedAddresse: {
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				contract: {
					customer_id: '',
					address_id: '',
					begin: '',
					end: null,
					title: '',
					mintermcount: null,
					mintermcycle: null,
					permanent: '',
					is_template: null,
					noticeperiodcount: null,
					noticeperiodcycle: null,
					autorenewalcount: null,
					autorenewalcycle: null,
					conclusion: null,
					settlement: null,
					comments: '',
					number: null,
					status: '',
					paymentmethod: '',
					settlementcycle: null,
					termination: null,
				},
				contractPositions: [],
				positionDialogSelectedItem: null,
				positionDialogShowDelete: false,
				positionDialog: {
					title: '',
					count: '',
					price: '',
					ek: '',
					vat: '',
					discount: '',
				},
			};
		},
		methods: {
			handleCustomerSelect(data) {
				this.contract.customer_id = data.id;

				const customersStore = useCustomersStore();
				customersStore
					.getCustomer(this.contract.customer_id)
					.then((response) => {
						this.selectedCustomer = response;
						this.contract.address_id = response.Addresses[0].id;
						this.selectedAddresse = response.Addresses[0];
						this.$refs.customerDialog.hide();
					});
			},
			openCustomerDialog() {
				this.$refs.customerDialog.show();
			},
			addContract() {
				if (
					this.contract.permanent == true ||
					this.contract.permanent == 1
				) {
					this.contract.permanent = 1;
				} else {
					this.contract.permanent = 0;
				}

				const contractsStore = useContractsStore();
				contractsStore.addContract(this.contract).then((response) => {
					const contractId = response.id;

					// add positions
					this.contractPositions.forEach((position) => {
						// convert price if string
						if (typeof position.price == 'string') {
							position.price = parseFloat(
								position.price.replace(',', '.')
							);
						}

						contractsStore.addContractPosition({
							positionData: position,
							contractId: contractId,
						});
					});

					this.$router.push({ name: 'ContractsIndex' });
				});
			},
			handleRowClick(item) {
				this.positionDialogShowDelete = true;
				this.editPosition(item);
			},
			openPositionDialog() {
				this.positionDialogShowDelete = false;

				// reset dialog data
				this.positionDialog.articlename = '';
				this.positionDialog.count = '';
				this.positionDialog.price = '';
				this.positionDialog.ek = '';
				this.positionDialog.vat = '';
				this.positionDialog.discount = '';
				this.positionDialogSelectedItem = null;

				this.$refs.positionDialog.show();
			},
			savePosition() {
				if (this.positionDialogSelectedItem == null) {
					this.contractPositions.push({
						articlename: this.positionDialog.articlename,
						count: this.positionDialog.count,
						price: this.positionDialog.price,
						ek: this.positionDialog.ek,
						vat: this.positionDialog.vat,
						discount: this.positionDialog.discount,
					});
				} else {
					this.positionDialogSelectedItem.articlename =
						this.positionDialog.articlename;
					this.positionDialogSelectedItem.count =
						this.positionDialog.count;
					this.positionDialogSelectedItem.price =
						this.positionDialog.price;
					this.positionDialogSelectedItem.ek = this.positionDialog.ek;
					this.positionDialogSelectedItem.vat =
						this.positionDialog.vat;
					this.positionDialogSelectedItem.discount =
						this.positionDialog.discount;
				}

				this.$refs.positionDialog.hide();
			},
			editPosition(position) {
				this.positionDialogSelectedItem = position;
				this.positionDialog.articlename =
					this.positionDialogSelectedItem.articlename;
				this.positionDialog.count =
					this.positionDialogSelectedItem.count;
				this.positionDialog.price =
					this.positionDialogSelectedItem.price;
				this.positionDialog.ek = this.positionDialogSelectedItem.ek;
				this.positionDialog.vat = this.positionDialogSelectedItem.vat;
				this.positionDialog.discount =
					this.positionDialogSelectedItem.discount;
				this.$refs.positionDialog.show();
			},
			deletePosition() {
				for (let i = 0; i < this.contractPositions.length; i++) {
					if (
						this.contractPositions[i] ==
						this.positionDialogSelectedItem
					) {
						this.contractPositions.splice(i, 1);
					}
				}
				this.$refs.positionDialog.hide();
			},
		},
	};
</script>

<style></style>
