import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useTransactionsStore = defineStore('transactions', {
	state: () => ({
		transactions: [],
	}),
	actions: {
		async getTransaction({ transactionId, accountId }) {
			let transaction = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'accounts/' +
				accountId +
				'/transactions/' +
				transactionId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						transactions: 1,
					},
				})
				.then((response) => {
					transaction = response.data;
				});

			return transaction;
		},
		async addTransaction({ transactionData, accountId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let transaction = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'accounts/' +
				accountId +
				'/transactions';
			await axios
				.post(url, transactionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					transaction = response.data;
				});

			return transaction;
		},
		async editTransaction({ transactionId, transactionData, accountId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let transaction = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'accounts/' +
				accountId +
				'/transactions/' +
				transactionId;
			await axios
				.put(url, transactionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					transaction = response.data;
				});

			return transaction;
		},
		async deleteTransaction({ transactionId, accountId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'accounts/' +
				accountId +
				'/transactions/' +
				transactionId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async getTransactionsNotAssigned() {
			let receipts = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'transactions/notAssigned';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					receipts = response.data;
				});

			return receipts;
		},
		async importTransactionsCSV({ csvData, accountId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let responseData;
			const data = {
				file: csvData,
			};

			const url =
				managerpanel_accountingmanagement_backend_url +
				'accounts/' +
				accountId +
				'/transactions/importFromCsv';
			await axios
				.post(url, data, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					responseData = response.data;
				});

			return responseData;
		},
	},
});
