<template>
	<div class="min-h-screen flex flex-row bg-gray-100 dark:bg-gray-900">
		<Sidebar />

		<div class="container mx-auto">
			<router-view />
		</div>
	</div>
</template>

<script>
	import Sidebar from './components/sidebar.vue';
	import 'boxicons';

	import './assets/tailwind.css';

	export default {
		name: 'App',
		components: {
			Sidebar,
		},
		mounted() {
			document.querySelector('html').classList.add('dark');
		},
	};
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
</style>
