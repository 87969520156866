import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useReceiptsStore = defineStore('receipts', {
	state: () => ({
		receipts: [],
		receiptmanufactors: [],
		receiptcategories: [],
	}),
	actions: {
		async getReceiptmanufactors(options = { receipts: 1 }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receiptmanufactors';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						receipts: options.receipts,
					},
				})
				.then((response) => {
					that.receiptmanufactors = response.data;
				});

			return this.receiptmanufactors;
		},
		async addReceiptmanufactor(receiptmanufactorData) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let receiptmanufactor = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receiptmanufactors';

			await axios
				.post(url, receiptmanufactorData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					receiptmanufactor = response.data;
				});

			return receiptmanufactor;
		},
		async getReceiptcategories() {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receiptcategories';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					that.receiptcategories = response.data;
				});

			return this.receiptcategories;
		},
		async getReceiptStatistics() {
			let receiptcategories = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receiptcategories?receipts=1';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					receiptcategories = response.data;
				});

			return receiptcategories;
		},
		async getReceipt({ manufactorId, receiptId }) {
			let receipt = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receiptmanufactors/' +
				manufactorId +
				'/receipts/' +
				receiptId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					receipt = response.data;
				});

			return receipt;
		},
		async addReceipt({ receiptmanufactorId, receiptData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let receipt = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receiptmanufactors/' +
				receiptmanufactorId +
				'/receipts';
			await axios
				.post(url, receiptData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					receipt = response.data;
				});

			return receipt;
		},
		async editReceipt({ receiptmanufactorId, receiptId, receiptData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let receipt = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receiptmanufactors/' +
				receiptmanufactorId +
				'/receipts/' +
				receiptId;
			await axios
				.put(url, receiptData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					receipt = response.data;
				});

			return receipt;
		},
		async getReceiptsNotAssigned() {
			let receipts = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receipts/notAssigned?receiptmanufactor=1';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					receipts = response.data;
				});

			return receipts;
		},
		async connectReceiptTransactions({ receipts, transactions }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let responseObj;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'receipts/multipleAssignTransactions';
			await axios
				.post(
					url,
					{ receipts: receipts, transactions: transactions },
					{
						headers: {
							Authorization: jwt,
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					responseObj = response;
				});

			return responseObj;
		},
	},
});
