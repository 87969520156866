<template>
	<p>Articles View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="invoice.number" type="text"
						>Number</w-input
					>
				</div>
				<div class="mb-4">
					<w-datepicker v-model="invoice.invoicedate"
						>Invoice Date</w-datepicker
					>
				</div>
				<div class="mb-4">
					<w-datepicker v-model="invoice.billingdate"
						>Billing Date</w-datepicker
					>
				</div>
				<div class="mb-4">
					<w-select :options="statusOptions" v-model="invoice.status"
						>Status</w-select
					>
				</div>
				<w-btn @click="this.editInvoice()">Bearbeiten</w-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import {
		WInput,
		WSelect,
		WBtn,
		WDatepicker,
	} from '@wirecore/wirecomponents';
	import { useInvoicesStore } from './../../store/invoices';

	export default {
		name: 'InvoicesEdit',
		components: {
			WBtn,
			WDatepicker,
			WSelect,
			WInput,
		},
		data() {
			return {
				statusOptions: [
					{
						name: 'Zu bezahlen',
						value: 'open',
					},
					{
						name: 'Bezahlt',
						value: 'paid',
					},
				],
				invoice: {
					id: '',
					customer_id: '',
					address_id: '',
					account_id: '',
					type: '',
					billingdate: '',
					invoicedate: '',
					number: '',
					reduction: '',
					reference: '',
					status: '',
					storno: '',
					stornodate: '',
					exported: '',
					file: '',
				},
			};
		},
		mounted() {
			const invoiceId = this.$route.params.id;

			const invoicesStore = useInvoicesStore();
			invoicesStore.getInvoice(invoiceId).then((response) => {
				if (response.billingdate != null) {
					response.billingdate = moment(response.billingdate).format(
						'YYYY-MM-DD'
					);
				}
				response.invoicedate = moment(response.invoicedate).format(
					'YYYY-MM-DD'
				);

				this.invoice = response;
			});
		},
		methods: {
			editInvoice() {
				let invoiceData = this.invoice;
				invoiceData.invoicedate = moment(
					invoiceData.invoicedate
				).format('YYYY-MM-DD HH:mm:ss');
				if (
					invoiceData.billingdate !== undefined &&
					invoiceData.billingdate !== null &&
					invoiceData.billingdate !== ''
				) {
					invoiceData.billingdate = moment(
						invoiceData.billingdate
					).format('YYYY-MM-DD HH:mm:ss');
				}

				const invoicesStore = useInvoicesStore();
				invoicesStore
					.editInvoice({
						invoiceData: this.invoice,
						invoiceId: this.invoice.id,
					})
					.then((response) => {
						if (response != null) {
							this.$router.push({ name: 'Invoices' });
						}
					});
			},
		},
	};
</script>

<style></style>
