<template>
	<p>Customer View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'B2B', value: 'b2b' },
							{ name: 'B2C', value: 'b2c' },
						]"
						v-model="customer.type"
						>Type</w-select
					>
				</div>
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Herr', value: 'Herr' },
							{ name: 'Frau', value: 'Frau' },
						]"
						v-model="customer.salutation"
						>Salutation</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.firstname" type="text"
						>Firstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.lastname" type="text"
						>Lastname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.ustid" type="text"
						>UST-ID</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="customer.position" type="text"
						>Position</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.organisation" type="text"
						>Organisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.taxnumber" type="text"
						>Taxnumber</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<w-btn @click="this.openAdresseDialog()">Add Adresse</w-btn>
		<w-dialog ref="addresseDialog">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Private', value: 'private' },
							{ name: 'Business', value: 'business' },
						]"
						v-model="addresseDialog.type"
						>Type</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.street" type="text"
						>Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.plz" type="text"
						>Plz</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.city" type="text"
						>City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.country" type="text"
						>Country</w-input
					>
				</div>
				<w-btn @click="saveAddresse()">Save</w-btn>
			</div>
		</w-dialog>
		<w-table
			:headers="[
				{ text: 'Type', value: 'type' },
				{ text: 'Straße', value: 'street' },
				{ text: 'Postleitzahl', value: 'plz' },
				{ text: 'Stadt', value: 'city' },
				{ text: 'Land', value: 'country' },
			]"
			:items="customer.Addresses"
		/>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<w-btn @click="this.openPhonenumberDialog()">Add Number</w-btn>
				<w-dialog ref="phonenumberDialog">
					<div class="container mx-auto">
						<div class="mb-4">
							<w-select
								:options="[
									{ name: 'Private', value: 'private' },
									{ name: 'Business', value: 'business' },
								]"
								v-model="phonenumberDialog.type"
								>Type</w-select
							>
						</div>
						<div class="mb-4">
							<w-input
								v-model="phonenumberDialog.number"
								type="text"
								>Number</w-input
							>
						</div>
						<w-btn @click="savePhonenumber()">Save</w-btn>
					</div>
				</w-dialog>
				<w-table
					:headers="[
						{ text: 'Type', value: 'type' },
						{ text: 'Number', value: 'number' },
					]"
					:items="customer.Phonenumbers"
				/>
			</div>
			<div class="container mx-auto">
				<w-btn @click="this.openMailDialog()">Add Mail</w-btn>
				<w-dialog ref="mailDialog">
					<div class="container mx-auto">
						<div class="mb-4">
							<w-select
								:options="[
									{ name: 'Private', value: 'private' },
									{ name: 'Business', value: 'business' },
								]"
								v-model="mailDialog.type"
								>Type</w-select
							>
						</div>
						<div class="mb-4">
							<w-input v-model="mailDialog.mail" type="text"
								>Mail</w-input
							>
						</div>
						<w-btn @click="saveMail()">Save</w-btn>
					</div>
				</w-dialog>
				<w-table
					:headers="[
						{ text: 'Type', value: 'type' },
						{ text: 'Mail', value: 'mail' },
					]"
					:items="customer.Mails"
				/>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="m-4">
			<w-btn
				@click="this.addCustomer()"
				:disabled="button.disabled"
				:loading="button.loading"
				>Hinzufügen</w-btn
			>
		</div>
	</div>
</template>

<script>
	import {
		WSelect,
		WInput,
		WDialog,
		WBtn,
		WTable,
	} from '@wirecore/wirecomponents';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'CustomerAdd',
		components: {
			WSelect,
			WInput,
			WDialog,
			WBtn,
			WTable,
		},
		data() {
			return {
				button: {
					loading: false,
					disabled: false,
				},
				customer: {
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					position: '',
					organisation: '',
					taxnumber: '',
					ustid: '',
					Phonenumbers: [],
					Mails: [],
					Addresses: [],
				},
				addresseDialog: {
					type: '',
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				phonenumberDialog: {
					type: '',
					number: '',
				},
				mailDialog: {
					type: '',
					mail: '',
				},
			};
		},
		methods: {
			addCustomer() {
				this.button.disabled = true;
				this.button.loading = true;

				const customersStore = useCustomersStore();
				customersStore.addCustomer(this.customer).then((response) => {
					const customerId = response.id;

					// add addresses
					this.customer.Addresses.forEach((addresse) => {
						customersStore.addCustomerAddresse({
							addresseData: addresse,
							customerId: customerId,
						});
					});
					// add phonenumbers
					this.customer.Phonenumbers.forEach((phonemumber) => {
						customersStore.addCustomerPhonenumber({
							phonenumberData: phonemumber,
							customerId: customerId,
						});
					});
					// add mails
					this.customer.Mails.forEach((mail) => {
						customersStore.addCustomerMail({
							mailData: mail,
							customerId: customerId,
						});
					});

					this.$router.push({ name: 'Customers' });
				});
			},
			openAdresseDialog() {
				this.$refs.addresseDialog.show();
			},
			saveAddresse() {
				this.customer.Addresses.push({
					type: this.addresseDialog.type,
					street: this.addresseDialog.street,
					plz: this.addresseDialog.plz,
					city: this.addresseDialog.city,
					country: this.addresseDialog.country,
				});
			},
			openPhonenumberDialog() {
				this.$refs.phonenumberDialog.show();
			},
			savePhonenumber() {
				this.customer.Phonenumbers.push({
					type: this.phonenumberDialog.type,
					number: this.phonenumberDialog.number,
				});
			},
			openMailDialog() {
				this.$refs.mailDialog.show();
			},
			saveMail() {
				this.customer.Mails.push({
					type: this.mailDialog.type,
					mail: this.mailDialog.mail,
				});
			},
		},
	};
</script>

<style></style>
