<template>
	<p>Costestimate Add</p>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-input v-model="costestimate.title" type="text" disabled
				>Title</w-input
			>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_type"
						type="text"
						disabled
						>Customertype</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_salutation"
						type="text"
						disabled
						>CustomerSalutation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_firstname"
						type="text"
						disabled
						>CustomerFirstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_lastname"
						type="text"
						disabled
						>CustomerLastname</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_organisation"
						type="text"
						disabled
						>Customerorganisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_street"
						type="text"
						disabled
						>Customer Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_plz"
						type="text"
						disabled
						>Customer PLZ</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_city"
						type="text"
						disabled
						>Customer City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_country"
						type="text"
						disabled
						>Customer Country</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<w-table
			:headers="[
				{ text: 'Title', value: 'articlename' },
				{ text: 'Count', value: 'count' },
				{ text: 'VK', value: 'price' },
				{ text: 'EK', value: 'ek' },
				{ text: 'Vat', value: 'vat' },
				{ text: 'Discount', value: 'vat' },
				{ text: 'Positionswert (Netto)', value: 'netto' },
			]"
			:items="costestimatePositions"
		/>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="costestimate.number" type="text" disabled
						>Number</w-input
					>
				</div>
			</div>
			<div class="mb-4">
				<w-select
					:options="[
						{ name: 'Wartet', value: 'wait' },
						{ name: 'Abgelehnt', value: 'declined' },
						{ name: 'Angenommen', value: 'accepted' },
					]"
					v-model="costestimate.status"
					disabled
					>Status</w-select
				>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="costestimate.expirydate"
						type="text"
						disabled
						>Expirydate</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea disabled v-model="costestimate.additionaltext"
				>Additional Text</w-textarea
			>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea disabled v-model="costestimate.comments"
				>Comments</w-textarea
			>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-btn @click="this.downloadCostestimatePdf()"
				>Kostenvoranschlag herunterladen</w-btn
			>
			<w-btn @click="this.genAssignment()">Auftrag erstellen</w-btn>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-card headline="Documents">
				<w-btn
					v-for="document in this.documents"
					v-bind:key="document.id"
					@click="this.downloadDocument(document)"
					>{{ document.name }}</w-btn
				>
			</w-card>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<label
				class="block text-gray-700 text-sm font-bold mb-2"
				for="username"
			>
				File
			</label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="username"
				type="file"
				@change="fileInputHandler"
				:value="documentInput"
			/>
			<label
				class="block text-gray-700 text-sm font-bold mb-2"
				for="username"
			>
				Name
			</label>
			<w-input v-model="documentUploadName" type="text"></w-input>
			<w-btn @click="this.uploadDocument()">Dokument hochladen</w-btn>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import {
		WInput,
		WBtn,
		WTable,
		WCard,
		WSelect,
		WTextarea,
	} from '@wirecore/wirecomponents';
	import { useCostestimatesStore } from './../../store/costestimates';

	export default {
		name: 'CostestimatesView',
		components: {
			WCard,
			WInput,
			WBtn,
			WTable,
			WSelect,
			WTextarea,
		},
		mounted() {
			const costestimateId = this.$route.params.id;

			const costestimatesStore = useCostestimatesStore();
			this.$nextTick(function () {
				costestimatesStore
					.getCostestimate(costestimateId)
					.then((response) => {
						this.costestimate = response;

						// convert start and end time to values that are can showing in fields
						this.costestimate.expirydate = moment(
							this.costestimate.expirydate
						).format('YYYY-MM-DDTHH:mm');

						if (this.costestimate.Customer != null) {
							this.selectedCustomer = this.costestimate.Customer;
						}
						if (this.costestimate.Address != null) {
							this.selectedAddresse = this.costestimate.Addresse;
						}
						this.costestimatePositions =
							this.costestimate.Costestimatepositions;
					});
			});

			costestimatesStore
				.getCostestimateDocuments(costestimateId)
				.then((response) => {
					this.documents = response;
				});
		},
		data() {
			return {
				customerShowedColumns: ['number', 'name', 'organisation'],
				customerActions: [
					{
						text: 'Select',
						emitName: 'setCustomer',
					},
				],
				customerHeaders: [
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Name',
						value: 'lastname',
					},
					{
						text: 'organisation',
						value: 'organisation',
					},
				],
				customers: [],
				selectedCustomer: {
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					organisation: '',
				},
				selectedAddresse: {
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				costestimate: {
					customer_id: '',
					address_id: '',
					number: '',
					status: '',
					title: '',
					customerType: '',
					customerSalutation: '',
					customerFirstname: '',
					customerLastname: '',
					customerOrganisation: '',
					customerStreet: '',
					customerPlz: '',
					customerCity: '',
					customerCountry: '',
					expirydate: '',
					additionaltext: '',
					comments: '',
				},
				costestimatePositions: [],
				customer: {
					//number: '',
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					position: '',
					organisation: '',
					taxnumber: '',
					ustid: '',
					Phonenumbers: [],
					Mails: [],
					Addresses: [],
				},
				positionDialogSelectedItem: null,
				positionDialog: {
					title: '',
					count: '',
					price: '',
					ek: '',
					vat: '',
					discount: '',
				},
				documents: [],
				documentFile: '',
				documentUploadFileBase64: '',
				documentUploadName: '',
			};
		},
		methods: {
			genAssignment() {
				const costestimatesStore = useCostestimatesStore();
				costestimatesStore
					.costestimateGenAssignment({
						costestimateId: this.costestimate.id,
					})
					.then((response) => {
						this.$router.push({
							name: 'AssignmentsView',
							params: { id: response.id },
						});
					});
			},
			downloadCostestimatePdf() {
				const costestimatesStore = useCostestimatesStore();
				costestimatesStore
					.getCostestimatePdf(this.costestimate.id)
					.then(async function (response) {
						const base64Response = await fetch(
							`data:application/pdf;base64,${response.file}`
						);
						const blob = await base64Response.blob();
						const fileURL = URL.createObjectURL(blob);
						// window.open(fileURL) -> opens the blod file in new tab -> blocked by chrome bacause popup
						const fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.download = 'Kostenvoranschlag';
						fileLink.click();
					});
			},
			async downloadDocument(documentFile) {
				const base64Response = await fetch(
					`data:application/pdf;base64,${documentFile.file}`
				);
				const blob = await base64Response.blob();
				const fileURL = URL.createObjectURL(blob);
				// window.open(fileURL) -> opens the blod file in new tab -> blocked by chrome bacause popup
				const fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.download = 'Delivery Note';
				fileLink.click();
			},
			fileInputHandler(event) {
				//Read File
				var selectedFile = event.target.files;
				//Check File is not Empty
				if (selectedFile.length > 0) {
					// Select the very first file from list
					var fileToLoad = selectedFile[0];
					// FileReader function for read the file.
					var fileReader = new FileReader();
					var base64;
					// Onload of file read the file content
					let that = this;
					fileReader.onload = function (fileLoadedEvent) {
						base64 = fileLoadedEvent.target.result;
						// Print data in console
						that.documentUploadFileBase64 = base64.split(',')[1];
					};
					// Convert data to base64
					fileReader.readAsDataURL(fileToLoad);
				}
			},
			uploadDocument() {
				const costestimateId = this.$route.params.id;
				const documentData = {
					file: this.documentUploadFileBase64,
					name: this.documentUploadName,
				};

				let that = this;

				const costestimatesStore = useCostestimatesStore();
				costestimatesStore
					.addCostestimateDocument({
						costestimateId: costestimateId,
						documentData: documentData,
					})
					.then((response) => {
						that.documents.push(response);
					});
			},
		},
	};
</script>

<style></style>
