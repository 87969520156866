<template>
	<p>Costestimate Add</p>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-input v-model="costestimate.title" type="text">Title</w-input>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-btn @click="this.openCustomerDialog()"
						>Select Customer</w-btn
					>
					<w-dialog ref="customerDialog">
						<w-datatable
							:headers="customerHeaders"
							:items="customers"
							:actions="customerActions"
							:showedColumns="customerShowedColumns"
							@setCustomer="handleCustomerSelect"
						/>
					</w-dialog>
				</div>
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'B2B', value: 'b2b' },
							{ name: 'B2C', value: 'b2c' },
						]"
						v-model="costestimate.customer_type"
						>Customer Type</w-select
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_salutation"
						type="text"
						>Customer Salutation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_firstname"
						type="text"
						>Customer Firstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_lastname"
						type="text"
						>Customer Lastname</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="costestimate.customer_organisation"
						type="text"
						>Customerorganisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="costestimate.customer_street" type="text"
						>Customer Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="costestimate.customer_plz" type="text"
						>Customer PLZ</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="costestimate.customer_city" type="text"
						>Customer City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="costestimate.customer_country" type="text"
						>Customer Country</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="m-4">
			<w-btn @click="this.openPositionDialog()">Add Position</w-btn>
		</div>
		<w-dialog ref="positionDialog">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="positionDialog.articlename" type="text"
						>Article</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.count" type="text"
						>Count</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.price" type="text"
						>VK</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.ek" type="text"
						>EK</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.vat" type="text"
						>Vat</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.discount" type="text"
						>Discount</w-input
					>
				</div>
				<w-btn @click="savePosition()">Save</w-btn>
				<w-btn
					v-if="positionDialogShowDelete == true"
					@click="deletePosition()"
					>Delete</w-btn
				>
			</div>
		</w-dialog>
		<w-table
			:headers="[
				{ text: 'Title', value: 'articlename' },
				{ text: 'Count', value: 'count' },
				{ text: 'VK', value: 'price' },
				{ text: 'EK', value: 'ek' },
				{ text: 'Vat', value: 'vat' },
				{ text: 'Discount', value: 'discount' },
				{ text: 'Positionswert (Netto)', value: 'netto' },
				{ text: 'Action' },
			]"
			:items="costestimatePositions"
			@rowclick="handleRowClick"
		>
			<template #actions>
				<a
					href="#"
					class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
					>Edit / Delete</a
				>
			</template>
		</w-table>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="costestimate.number" type="text" disabled
						>Number</w-input
					>
				</div>
			</div>
			<div class="mb-4">
				<w-select
					:options="[
						{ name: 'Wartet', value: 'wait' },
						{ name: 'Abgelehnt', value: 'declined' },
						{ name: 'Angenommen', value: 'accepted' },
					]"
					v-model="costestimate.status"
					>Status</w-select
				>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="costestimate.expirydate"
						>Expirydate</w-datepicker
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea v-model="costestimate.additionaltext"
				>Additional Text</w-textarea
			>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea v-model="costestimate.comments">Comments</w-textarea>
		</div>
	</div>
	<w-btn @click="this.addCostestimate()">Hinzufügen</w-btn>
</template>

<script>
	import moment from 'moment';

	import {
		WInput,
		WBtn,
		WTable,
		WSelect,
		WTextarea,
		WDialog,
		WDatatable,
		WDatepicker,
	} from '@wirecore/wirecomponents';
	import { useCostestimatesStore } from './../../store/costestimates';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'CostestimatesAdd',
		components: {
			WDialog,
			WInput,
			WBtn,
			WTable,
			WSelect,
			WTextarea,
			WDatatable,
			WDatepicker,
		},
		mounted() {
			this.$nextTick(function () {
				const customersStore = useCustomersStore();
				customersStore.getCustomers().then((response) => {
					this.customers = response;
				});
			});
		},
		data() {
			return {
				customerShowedColumns: ['number', 'name', 'organisation'],
				customerActions: [
					{
						text: 'Select',
						emitName: 'setCustomer',
					},
				],
				customerHeaders: [
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Name',
						value: 'lastname',
					},
					{
						text: 'organisation',
						value: 'organisation',
					},
				],
				customers: [],
				selectedCustomer: {
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					organisation: '',
				},
				selectedAddresse: {
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				costestimate: {
					customer_id: null,
					address_id: null,
					number: '',
					status: '',
					title: '',
					customer_type: '',
					customer_salutation: '',
					customer_firstname: '',
					customer_lastname: '',
					customer_organisation: '',
					customer_street: '',
					customer_plz: '',
					customer_city: '',
					customer_country: '',
					expirydate: '',
					additionaltext: '',
					comments: '',
				},
				costestimatePositions: [],
				customer: {
					//number: '',
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					position: '',
					organisation: '',
					taxnumber: '',
					ustid: '',
					Phonenumbers: [],
					Mails: [],
					Addresses: [],
				},
				positionDialogSelectedItem: null,
				positionDialogShowDelete: false,
				positionDialog: {
					title: '',
					count: '',
					price: '',
					ek: '',
					vat: '',
					discount: '',
				},
			};
		},
		methods: {
			handleCustomerSelect(data) {
				this.costestimate.customer_id = data.id;

				const customersStore = useCustomersStore();
				customersStore
					.getCustomer(this.costestimate.customer_id)
					.then((response) => {
						this.selectedCustomer = response;
						this.costestimate.address_id = response.Addresses[0].id;
						this.selectedAddresse = response.Addresses[0];

						// set data in costestimate
						this.costestimate.customer_type = response.type;
						this.costestimate.customer_salutation =
							response.salutation;
						this.costestimate.customer_firstname =
							response.firstname;
						this.costestimate.customer_lastname = response.lastname;
						this.costestimate.customer_organisation =
							response.organisation;
						this.costestimate.customer_street =
							this.selectedAddresse.street;
						this.costestimate.customer_plz =
							this.selectedAddresse.plz;
						this.costestimate.customer_city =
							this.selectedAddresse.city;
						this.costestimate.customer_country =
							this.selectedAddresse.country;

						this.$refs.customerDialog.hide();
					});
			},
			openCustomerDialog() {
				this.$refs.customerDialog.show();
			},
			addCostestimate() {
				// convert date
				this.costestimate.expirydate = moment(
					this.costestimate.expirydate
				).format('YYYY-MM-DD HH:mm:ss');

				const costestimatesStore = useCostestimatesStore();
				costestimatesStore
					.addCostestimate(this.costestimate)
					.then((response) => {
						const costestimateId = response.id;

						// add positions
						this.costestimatePositions.forEach((position) => {
							costestimatesStore.addCostestimatePosition({
								positionData: position,
								costestimateId: costestimateId,
							});
						});

						this.$router.push({ name: 'CostestimatesIndex' });
					});
			},
			handleRowClick(item) {
				this.positionDialogShowDelete = true;
				this.editPosition(item);
			},
			openPositionDialog() {
				this.positionDialogShowDelete = false;

				// reset dialog data
				this.positionDialog.articlename = '';
				this.positionDialog.count = '';
				this.positionDialog.price = '';
				this.positionDialog.ek = '';
				this.positionDialog.vat = '';
				this.positionDialog.discount = '';
				this.positionDialogSelectedItem = null;

				this.$refs.positionDialog.show();
			},
			savePosition() {
				if (this.positionDialogSelectedItem == null) {
					this.costestimatePositions.push({
						articlename: this.positionDialog.articlename,
						count: this.positionDialog.count,
						price: this.positionDialog.price,
						ek: this.positionDialog.ek,
						vat: this.positionDialog.vat,
						discount: this.positionDialog.discount,
					});
				} else {
					this.positionDialogSelectedItem.articlename =
						this.positionDialog.articlename;
					this.positionDialogSelectedItem.count =
						this.positionDialog.count;
					this.positionDialogSelectedItem.price =
						this.positionDialog.price;
					this.positionDialogSelectedItem.ek = this.positionDialog.ek;
					this.positionDialogSelectedItem.vat =
						this.positionDialog.vat;
					this.positionDialogSelectedItem.discount =
						this.positionDialog.discount;
				}

				this.$refs.positionDialog.hide();
			},
			editPosition(position) {
				this.positionDialogSelectedItem = position;
				this.positionDialog.articlename =
					this.positionDialogSelectedItem.articlename;
				this.positionDialog.count =
					this.positionDialogSelectedItem.count;
				this.positionDialog.price =
					this.positionDialogSelectedItem.price;
				this.positionDialog.ek = this.positionDialogSelectedItem.ek;
				this.positionDialog.vat = this.positionDialogSelectedItem.vat;
				this.positionDialog.discount =
					this.positionDialogSelectedItem.discount;
				this.$refs.positionDialog.show();
			},
			deletePosition() {
				for (let i = 0; i < this.costestimatePositions.length; i++) {
					if (
						this.costestimatePositions[i] ==
						this.positionDialogSelectedItem
					) {
						this.costestimatePositions.splice(i, 1);
					}
				}
				this.$refs.positionDialog.hide();
			},
		},
	};
</script>

<style></style>
