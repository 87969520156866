<template>
	<router-link to="/receipts/add">Add</router-link>

	<w-datatable
		:headers="headers"
		:items="receipts"
		:actions="actions"
		:showedColumns="showedColumns"
		@view="handleReceiptView"
		@edit="handleReceiptEdit"
		items_per_page="100"
	/>
</template>

<script>
	import { WDatatable } from '@wirecore/wirecomponents';
	import moment from 'moment';
	import { useReceiptsStore } from './../../store/receipts';

	export default {
		name: 'ReceiptsIndex',
		components: {
			WDatatable,
		},
		methods: {
			showDialog() {
				this.$refs.dialog.show();
			},
			handleReceiptView(data) {
				this.$router.push({
					name: 'ReceiptsView',
					params: { manufactorId: data.manufactor_id, id: data.id },
				});
			},
			handleReceiptEdit(data) {
				this.$router.push({
					name: 'ReceiptsEdit',
					params: { manufactorId: data.manufactor_id, id: data.id },
				});
			},
		},
		mounted() {
			this.$nextTick(function () {
				const receiptsStore = useReceiptsStore();
				receiptsStore.getReceiptmanufactors().then((response) => {
					let that = this;
					response.forEach(function (value) {
						value.Receipts.forEach(function (value2) {
							if (value2.type == 'income') {
								value2.type = 'Einnahme';
							} else {
								value2.type = 'Ausgabe';
							}
							value2.manufactor = value.name;
							value2.date = moment(value2.date).format(
								'DD.MM.YYYY'
							);
							value2.amount = value2.amount.toLocaleString(
								'de-DE',
								{ style: 'currency', currency: 'EUR' }
							);
							that.receipts.push(value2);
						});
					});
				});
			});
		},
		data() {
			return {
				dialogActive: false,
				showedColumns: [
					'type',
					'date',
					'manufactor',
					'number',
					'amount',
				],
				actions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
				],
				headers: [
					{
						text: 'Type',
						value: 'type',
					},
					{
						text: 'Date',
						value: 'date',
					},
					{
						text: 'Manufactor',
						value: 'manufactor',
					},
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Amount',
						value: 'amount',
					},
				],
				receipts: [],
			};
		},
	};
</script>

<style></style>
