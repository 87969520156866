<template>
	<div class="container mx-auto">
		<BarChart :chartData="barchartData" />
	</div>
</template>

<script>
	import { BarChart } from 'vue-chart-3';
	import { useReceiptsStore } from './../../store/receipts';

	export default {
		name: 'ReceiptsStatistics',
		components: {
			BarChart,
		},
		methods: {},
		mounted() {
			this.$nextTick(function () {
				const receiptsStore = useReceiptsStore();
				receiptsStore.getReceiptStatistics().then((response) => {
					let chartLabels = [];
					let chartData = [];
					response.forEach(function (value) {
						if (value.Receipts.length > 0) {
							let receiptsAmount = 0;
							value.Receipts.forEach(function (receipt) {
								receiptsAmount += receipt.amount;
							});

							chartLabels.push(value.name);
							chartData.push(receiptsAmount);
						}
					});

					this.barchartData.labels = chartLabels;
					this.barchartData.datasets[0].data = chartData;
				});
			});
		},
		data() {
			return {
				receipts: [],
				barchartData: {
					labels: [],
					datasets: [
						{
							label: 'Statistic',
							data: [],
							backgroundColor: ['#77CEFF'],
							fill: true,
						},
					],
				},
			};
		},
	};
</script>

<style></style>
