<template>
	<h1>Export Accounting</h1>
	<w-card>
		<div class="flex justify-evenly">
			<w-datepicker
				format="YYYY-MM-DD"
				v-model="export_accounting.startdate"
				>Startdate</w-datepicker
			>
			<w-datepicker
				format="YYYY-MM-DD"
				v-model="export_accounting.enddate"
				>Enddate</w-datepicker
			>
			<w-btn
				@click="this.exportAccounting()"
				:loading="export_accounting.button_loading"
				:disabled="export_accounting.button_loading"
				>Export</w-btn
			>
		</div>
	</w-card>
</template>

<script>
	import { WCard, WBtn, WDatepicker } from '@wirecore/wirecomponents';
	import { useExportStore } from './../store/export';

	export default {
		name: 'Export',
		components: {
			WBtn,
			WDatepicker,
			WCard,
		},
		data() {
			return {
				export_accounting: {
					button_loading: false,
					startdate: '2022-12-01',
					enddate: '2022-12-31',
				},
				downloadUrl: null,
			};
		},
		methods: {
			exportAccounting() {
				let dates = {
					startdate: this.export_accounting.startdate,
					enddate: this.export_accounting.enddate,
				};

				this.export_accounting.button_loading = true;
				let that = this;
				const exportStore = useExportStore();
				exportStore
					.getExportAccounting(dates)
					.then(async function (response) {
						const downloadUrl =
							process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL.slice(
								0,
								-1
							) + response.file;

						var a = document.createElement('a');
						document.body.appendChild(a);
						a.style = 'display:none';
						a.href = downloadUrl;
						a.download = 'accounting.zip';
						a.click();
						window.URL.revokeObjectURL(downloadUrl);
						a.remove();

						that.export_accounting.button_loading = false;
					});
			},
		},
	};
</script>

<style></style>
