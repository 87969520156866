<template>
	<router-link to="/articles/add">Add</router-link>

	<w-datatable
		:headers="headers"
		:items="articles"
		:actions="actions"
		:showedColumns="showedColumns"
		@view="handleArticleView"
		@edit="handleArticleEdit"
	/>
</template>

<script>
	import { WDatatable } from '@wirecore/wirecomponents';
	import { useArticlesStore } from './../../store/articles';

	export default {
		name: 'ArticlesIndex',
		components: {
			WDatatable,
		},
		methods: {
			showDialog() {
				this.$refs.dialog.show();
			},
			handleArticleView(data) {
				this.$router.push({
					name: 'ArticlesView',
					params: { id: data.id },
				});
			},
			handleArticleEdit(data) {
				this.$router.push({
					name: 'ArticlesEdit',
					params: { id: data.id },
				});
			},
		},
		mounted() {
			this.$nextTick(function () {
				const articlesStore = useArticlesStore();
				articlesStore.getArticles().then((response) => {
					this.articles = response;
				});
			});
		},
		data() {
			return {
				dialogActive: false,
				showedColumns: [
					'number',
					'title',
					'ek',
					'vk',
					'manufactor',
					'active',
				],
				actions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
				],
				headers: [
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Title',
						value: 'title',
					},
					{
						text: 'EK',
						value: 'ek',
					},
					{
						text: 'VK',
						value: 'vk',
					},
					{
						text: 'Manufactor',
						value: 'manufactor',
					},
					{
						text: 'Active',
						value: 'active',
					},
				],
				articles: [],
			};
		},
	};
</script>

<style></style>
