<template>
	<p>Articles View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Einname', value: 'income' },
							{ name: 'Ausgabe', value: 'output' },
						]"
						v-model="receipt.type"
						disabled
						>Type</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="receipt.number" type="text" disabled
						>Belegnummer</w-input
					>
				</div>
				<div class="mb-4">
					<w-datepicker v-model="receipt.date" disabled
						>Date</w-datepicker
					>
				</div>
				<div class="mb-4">
					<w-select
						:options="this.receiptmanufactors"
						v-model="receipt.manufactor_id"
						disabled
						>Manufactor</w-select
					>
				</div>
				<div class="mb-4">
					<w-textarea v-model="receipt.description" disabled
						>Description</w-textarea
					>
				</div>
				<div class="mb-4">
					<w-select
						:options="this.receiptcategories"
						v-model="receipt.category_id"
						disabled
						>Category</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="receipt.vat" type="text" disabled
						>Vat</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="receipt.amount" type="text" disabled
						>Amount</w-input
					>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-1">
			<template v-if="showedPdf == null">
				<p>Loading ...</p>
			</template>
			<template v-else>
				<a :href="showedPdf" download="file.pdf">Download</a>
				<a
					v-if="stornoPdf != null"
					:href="stornoPdf"
					download="stornofile.pdf"
					>Download Storno</a
				>
				<div v-if="pageCount > 1">
					<button @click="currentPage--">Vorherige Seite</button>
					<button @click="currentPage++">Nächste Seite</button>
				</div>
				<div>
					{{ currentPage }} / {{ pageCount }}
					<pdf
						:src="showedPdf"
						:page="currentPage"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					/>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import pdfvuer from 'pdfvuer';
	import moment from 'moment';
	import {
		WSelect,
		WInput,
		WDatepicker,
		WTextarea,
	} from '@wirecore/wirecomponents';
	import { useReceiptsStore } from './../../store/receipts';

	export default {
		name: 'ReceiptView',
		components: {
			pdf: pdfvuer,
			WSelect,
			WInput,
			WDatepicker,
			WTextarea,
		},
		data() {
			return {
				receiptmanufactors: [],
				receiptcategories: [],
				receipt: {
					amount: '',
					category_id: '',
					date: '',
					description: '',
					file: '',
					id: '',
					invoice_id: '',
					manufactor_id: '',
					number: '',
					type: '',
					vat: '',
				},
				currentPage: 0,
				pageCount: 0,
				showedPdf: null,
				stornoPdf: null,
			};
		},
		mounted() {
			const receiptId = this.$route.params.id;
			const manufactorId = this.$route.params.manufactorId;

			const receiptsStore = useReceiptsStore();
			receiptsStore.getReceiptcategories().then((response) => {
				for (let i = 0; i < response.length; i++) {
					this.receiptcategories.push({
						name: response[i].name,
						value: response[i].id,
					});
				}
			});

			receiptsStore.getReceiptmanufactors().then((response) => {
				for (let i = 0; i < response.length; i++) {
					this.receiptmanufactors.push({
						name: response[i].name,
						value: response[i].id,
					});
				}
			});

			let that = this;
			receiptsStore
				.getReceipt({
					manufactorId: manufactorId,
					receiptId: receiptId,
				})
				.then(async function (response) {
					that.receipt = response;
					that.receipt.date = moment(that.receipt.date).format(
						'YYYY-MM-DDTHH:mm'
					);

					if (that.receipt.file != null) {
						that.showedPdf =
							'data:application/pdf;base64,' + that.receipt.file;

						// TODO implement better navigation. look at https://github.com/arkokoley/pdfvuer
						that.currentPage = 1;

						const reader = new FileReader();
						const base64Response = await fetch(
							`data:application/pdf;base64,${that.receipt.file}`
						);
						const blob = await base64Response.blob();
						reader.readAsBinaryString(blob);
						reader.onloadend = () => {
							let count = 1;
							if (
								reader.result.match(
									/\/Type[\s]*\/Page[^s]/g
								) !== null
							) {
								count = reader.result.match(
									/\/Type[\s]*\/Page[^s]/g
								).length;
							}

							that.pageCount = count;
						};
					}
				});
		},
	};
</script>

<style></style>
