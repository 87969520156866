<template>
	<p>Transaction Add</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datetimepicker v-model="transaction.booking"
						>Booking</w-datetimepicker
					>
				</div>
				<div class="mb-4">
					<w-input v-model="transaction.informations" type="text"
						>Informations</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="transaction.amount" type="text"
						>Amount</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<w-btn @click="this.addTransaction()">Hinzufügen</w-btn>
</template>

<script>
	import moment from 'moment';
	import { WInput, WBtn, WDatetimepicker } from '@wirecore/wirecomponents';
	import { useTransactionsStore } from './../../store/transactions';

	export default {
		name: 'TransactionsAdd',
		components: {
			WInput,
			WBtn,
			WDatetimepicker,
		},
		data() {
			return {
				transaction: {
					account_id: '',
					booking: '',
					informations: '',
					amount: '',
				},
			};
		},
		methods: {
			addTransaction() {
				this.transaction.account_id = this.$route.params.id;
				this.transaction.booking = moment(
					this.transaction.booking
				).format('YYYY-MM-DD HH:mm:ss');

				const transactionsStore = useTransactionsStore();
				transactionsStore
					.addTransaction({
						accountId: this.transaction.account_id,
						transactionData: this.transaction,
					})
					.then((response) => {
						this.$router.push({
							name: 'AccountsView',
							params: { id: response.account_id },
						});
					});
			},
		},
	};
</script>

<style></style>
