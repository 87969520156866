import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useCostestimatesStore = defineStore('costestimates', {
	state: () => ({
		costestimates: [],
	}),
	actions: {
		async getCostestimates() {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url + 'costestimates';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					that.costestimates = response.data;
				});

			return this.costestimates;
		},
		async getCostestimate(costestimateId) {
			let costestimate = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						addresse: 1,
						customer: 1,
						costestimatepositions: 1,
					},
				})
				.then((response) => {
					costestimate = response.data;
				});

			return costestimate;
		},
		async getCostestimatePdf(costestimateId) {
			let costestimate = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId +
				'/genPdf';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					costestimate = response.data;
				});

			return costestimate;
		},
		async getCostestimateDocuments(costestimateId) {
			let documents = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId +
				'/documents';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					documents = response.data;
				});

			return documents;
		},
		async addCostestimateDocument({ costestimateId, documentData }) {
			let document = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId +
				'/documents';

			await axios
				.post(url, documentData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					document = response.data;
				});

			return document;
		},
		async addCostestimate(costestimateData) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let costestimate = null;

			const url =
				managerpanel_accountingmanagement_backend_url + 'costestimates';
			await axios
				.post(url, costestimateData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					costestimate = response.data;
				});

			return costestimate;
		},
		async editCostestimate({ costestimateId, costestimateData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let costestimate = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId;
			await axios
				.put(url, costestimateData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					costestimate = response.data;
				});

			return costestimate;
		},
		async addCostestimatePosition({ positionData, costestimateId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId +
				'/costestimatepositions';
			await axios
				.post(url, positionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async editCostestimatePosition({
			positionData,
			costestimateId,
			positionId,
		}) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId +
				'/costestimatepositions/' +
				positionId;
			await axios
				.put(url, positionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async deleteCostestimatePosition({ costestimateId, positionId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId +
				'/costestimatepositions/' +
				positionId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async costestimateGenAssignment({ costestimateId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let assignment = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'costestimates/' +
				costestimateId +
				'/genAssignment';
			await axios
				.post(
					url,
					{},
					{
						headers: {
							Authorization: jwt,
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					assignment = response.data;
				});

			return assignment;
		},
	},
});
