<template>
	<p>Transaction View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datetimepicker v-model="transaction.booking" disabled
						>Booking</w-datetimepicker
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="transaction.informations"
						type="text"
						disabled
						>Informations</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="transaction.amount" type="text" disabled
						>Amount</w-input
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import { WInput, WDatetimepicker } from '@wirecore/wirecomponents';
	import { useTransactionsStore } from './../../store/transactions';

	export default {
		name: 'TransactionsView',
		components: {
			WInput,
			WDatetimepicker,
		},
		data() {
			return {
				transaction: {
					account_id: '',
					booking: '',
					informations: '',
					amount: '',
				},
			};
		},
		mounted() {
			this.$nextTick(function () {
				const accountId = this.$route.params.account_id;
				const transactionId = this.$route.params.id;

				const transactionsStore = useTransactionsStore();
				transactionsStore
					.getTransaction({
						accountId: accountId,
						transactionId: transactionId,
					})
					.then((response) => {
						this.transaction = response;

						// convert start and end time to values that are can showing in fields
						this.transaction.booking = moment(
							this.transaction.booking
						).format('YYYY-MM-DDTHH:mm');
					});
			});
		},
		methods: {},
	};
</script>

<style></style>
