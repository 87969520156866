<template>
	<p>Customer View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="customer.number" disabled type="text"
						>Kundennummer</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.type" disabled type="text"
						>Type</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="fullname" disabled type="text"
						>Name</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.ustid" disabled type="text"
						>UST-ID</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="customer.position" disabled type="text"
						>Position</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="customer.organisation"
						disabled
						type="text"
						>Organisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.taxnumber" disabled type="text"
						>Taxnumber</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<h2>Addresses</h2>
		<w-table
			:headers="[
				{ text: 'Type', value: 'type' },
				{ text: 'Straße', value: 'street' },
				{ text: 'Postleitzahl', value: 'plz' },
				{ text: 'Stadt', value: 'city' },
				{ text: 'Land', value: 'country' },
			]"
			:items="customer.Addresses"
		/>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<h2>Phonenumbers</h2>
				<w-table
					:headers="[
						{ text: 'Type', value: 'type' },
						{ text: 'Number', value: 'number' },
					]"
					:items="customer.Phonenumbers"
				/>
			</div>
			<div class="container mx-auto">
				<h2>Mails</h2>
				<w-table
					:headers="[
						{ text: 'Type', value: 'type' },
						{ text: 'Mail', value: 'mail' },
					]"
					:items="customer.Mails"
				/>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<h2>Invoices</h2>
		<w-table
			:headers="[
				{ text: 'Status', value: 'status' },
				{ text: 'Number', value: 'number' },
				{ text: 'Rechnungsdatum', value: 'invoicedate' },
				{ text: 'Bezahlt am', value: 'billingdate' },
				{ text: 'Action' },
			]"
			:items="customer.Invoices"
			@rowclick="handleRowClick"
		>
			<template #actions>
				<a
					href="#"
					class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
					>Edit</a
				>
			</template>
		</w-table>
	</div>
</template>

<script>
	import { WInput, WTable } from '@wirecore/wirecomponents';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'CustomerView',
		components: {
			WInput,
			WTable,
		},
		data() {
			return {
				customer: {
					firstname: '',
					lastname: '',
				},
			};
		},
		computed: {
			fullname() {
				return this.customer.firstname + ' ' + this.customer.lastname;
			},
		},
		methods: {
			handleRowClick(item) {
				this.$router.push({
					name: 'InvoicesView',
					params: { id: item.id },
				});
			},
		},
		mounted() {
			const customerId = this.$route.params.id;

			const customersStore = useCustomersStore();
			customersStore.getCustomer(customerId).then((response) => {
				response.Invoices.forEach(function (value) {
					if (value.billingdate != null) {
						value.status = 'Bezahlt';
					} else {
						value.status = 'Offen';
					}
				});

				this.customer = response;
			});
		},
	};
</script>

<style></style>
