import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useExportStore = defineStore('export', {
	state: () => ({
		accounts: [],
	}),
	actions: {
		async getExportAccounting(data) {
			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url =
				managerpanel_accountingmanagement_backend_url +
				'exportaccounting?startdate=' +
				data.startdate +
				'&enddate=' +
				data.enddate;

			let zipdata = null;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					zipdata = response.data;
				});

			return zipdata;
		},
	},
});
