<template>
	<p>Customer Edit</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-btn @click="this.openCustomerDialog()"
						>Select Customer</w-btn
					>
					<w-dialog ref="customerDialog">
						<w-datatable
							:headers="customerHeaders"
							:items="customers"
							:actions="customerActions"
							:showedColumns="customerShowedColumns"
							@setCustomer="handleCustomerSelect"
						/>
					</w-dialog>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.type" type="text"
						>Customer Type</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.salutation" type="text"
						>Customer Salutation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.firstname" type="text"
						>Customer Firstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedCustomer.lastname" type="text"
						>Customer Lastname</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="selectedCustomer.organisation" type="text"
						>Customerorganisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.street" type="text"
						>Customer Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.plz" type="text"
						>Customer PLZ</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.city" type="text"
						>Customer City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.country" type="text"
						>Customer Country</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="m-4">
			<w-btn @click="this.openPositionDialog()">Add Position</w-btn>
		</div>
		<w-dialog ref="positionDialog">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="positionDialog.articlename" type="text"
						>Article</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.count" type="text"
						>Count</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.price" type="text"
						>VK</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.ek" type="text"
						>EK</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.vat" type="text"
						>Vat</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="positionDialog.discount" type="text"
						>Discount</w-input
					>
				</div>
				<w-btn @click="savePosition()">Save</w-btn>
				<w-btn
					v-if="positionDialogShowDelete == true"
					@click="deletePosition()"
					>Delete</w-btn
				>
			</div>
		</w-dialog>
		<w-table
			:headers="[
				{ text: 'Title', value: 'articlename' },
				{ text: 'Count', value: 'count' },
				{ text: 'VK', value: 'price' },
				{ text: 'EK', value: 'ek' },
				{ text: 'Vat', value: 'vat' },
				{ text: 'Discount', value: 'discount' },
				{ text: 'Positionswert (Netto)', value: 'netto' },
			]"
			:items="assignmentPositions"
			@rowclick="handleRowClick"
		>
			<template #actions>
				<a
					href="#"
					class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
					>Edit / Delete</a
				>
			</template>
		</w-table>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Einmalig', value: 'unique' },
							{ name: 'Auf Rate', value: 'rate' },
						]"
						v-model="assignment.paymenttype"
						>Payment Type</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="assignment.ratecount" type="text"
						>Ratecount</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Täglich', value: 'daily' },
							{ name: 'Wöchentlich', value: 'weekly' },
							{ name: 'Monatlich', value: 'monthly' },
							{ name: 'Quatal', value: 'quatal' },
							{ name: 'Jährlich', value: 'yearly' },
						]"
						v-model="assignment.ratecycle"
						>Ratecycle</w-select
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="assignment.number" type="text" disabled
						>Assignment Number</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="assignment.starttime"
						>Starttime</w-datepicker
					>
				</div>
			</div>
			<div class="mb-4">
				<w-select
					:options="[
						{ name: 'Zu erledigen', value: 'todo' },
						{ name: 'Wird erledigt', value: 'bedo' },
						{ name: 'Erledigt', value: 'finished' },
					]"
					v-model="assignment.status"
					>Status</w-select
				>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="assignment.endtime"
						>Endtime</w-datepicker
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea v-model="assignment.comments">Comments</w-textarea>
		</div>
	</div>
	<w-btn @click="this.editAssignment()">Hinzufügen</w-btn>
</template>

<script>
	import moment from 'moment';
	import {
		WInput,
		WBtn,
		WTable,
		WSelect,
		WTextarea,
		WDatepicker,
	} from '@wirecore/wirecomponents';
	import { useAssignmentsStore } from './../../store/assignments';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'AssignmentsEdit',
		components: {
			WBtn,
			WInput,
			WTable,
			WSelect,
			WTextarea,
			WDatepicker,
		},
		mounted() {
			this.$nextTick(function () {
				const customersStore = useCustomersStore();
				customersStore.getCustomers().then((response) => {
					this.customers = response;
				});

				const assignmentId = this.$route.params.id;

				const assignmentsStore = useAssignmentsStore();
				assignmentsStore
					.getAssignment(assignmentId)
					.then((response) => {
						this.assignment = response;

						// convert start and end time to values that are can showing in fields
						this.assignment.starttime = moment(
							this.assignment.starttime
						).format('YYYY-MM-DD HH:mm:ss');
						this.assignment.endtime = moment(
							this.assignment.endtime
						).format('YYYY-MM-DD HH:mm:ss');

						this.selectedCustomer = this.assignment.Customer;
						this.selectedAddresse = this.assignment.Address;
						this.assignmentPositions =
							this.assignment.Assignmentpositions;
					});
			});
		},
		data() {
			return {
				customerShowedColumns: ['number', 'name', 'organisation'],
				customerActions: [
					{
						text: 'Select',
						emitName: 'setCustomer',
					},
				],
				customerHeaders: [
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Name',
						value: 'lastname',
					},
					{
						text: 'organisation',
						value: 'organisation',
					},
				],
				customers: [],
				selectedCustomer: {
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					organisation: '',
				},
				selectedAddresse: {
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				assignmentPositions: [],
				deletedAssignmentPositions: [],
				assignment: {
					id: '',
					customer_id: '',
					address_id: '',
					starttime: null,
					endtime: null,
					number: '',
					status: '',
					comments: '',
					ratecount: null,
					ratecycle: null,
					paymenttype: '',
					price: {
						netto: '',
						brutto: '',
						vat: '',
						vat_percent: '',
					},
				},
				customer: {
					//number: '',
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					position: '',
					organisation: '',
					taxnumber: '',
					ustid: '',
					Phonenumbers: [],
					Mails: [],
					Addresses: [],
				},
				positionDialogSelectedItem: null,
				positionDialogShowDelete: false,
				positionDialog: {
					title: '',
					count: '',
					price: '',
					ek: '',
					vat: '',
					discount: '',
				},
				phonenumberDialog: {
					type: '',
					number: '',
				},
				mailDialog: {
					type: '',
					mail: '',
				},
			};
		},
		methods: {
			handleCustomerSelect(data) {
				this.assignment.customer_id = data.id;

				const customersStore = useCustomersStore();
				customersStore
					.getCustomer(this.assignment.customer_id)
					.then((response) => {
						this.selectedCustomer = response;
						this.assignment.address_id = response.Addresses[0].id;
						this.selectedAddresse = response.Addresses[0];
						this.$refs.customerDialog.hide();
					});
			},
			openCustomerDialog() {
				this.$refs.customerDialog.show();
			},
			editAssignment() {
				if (this.assignment.starttime != null) {
					this.assignment.starttime = moment(
						this.assignment.starttime
					).format('YYYY-MM-DD HH:mm:ss');
				}
				if (this.assignment.endtime != null) {
					this.assignment.endtime = moment(
						this.assignment.endtime
					).format('YYYY-MM-DD HH:mm:ss');
				}

				const assignmentsStore = useAssignmentsStore();
				assignmentsStore
					.editAssignment({
						assignmentId: this.assignment.id,
						assignmentData: this.assignment,
					})
					.then((response) => {
						const assignmentId = response.id;

						// add positions
						this.assignmentPositions.forEach((position) => {
							// convert price if string
							if (typeof position.price == 'string') {
								position.price = parseFloat(
									position.price.replace(',', '.')
								);
							}

							if (position.id !== undefined) {
								assignmentsStore.editAssignmentPosition({
									positionData: position,
									assignmentId: assignmentId,
									positionId: position.id,
								});
							} else {
								assignmentsStore.addAssignmentPosition({
									positionData: position,
									assignmentId: assignmentId,
								});
							}
						});

						this.deletedAssignmentPositions.forEach((position) => {
							assignmentsStore.deleteAssignmentPosition({
								assignmentId: assignmentId,
								positionId: position,
							});
						});

						this.$router.push({ name: 'AssignmentsIndex' });
					});
			},
			handleRowClick(item) {
				this.positionDialogShowDelete = true;
				this.editPosition(item);
			},
			openPositionDialog() {
				this.positionDialogShowDelete = false;

				// reset dialog data
				this.positionDialog.articlename = '';
				this.positionDialog.count = '';
				this.positionDialog.price = '';
				this.positionDialog.ek = '';
				this.positionDialog.vat = '';
				this.positionDialog.discount = '';
				this.positionDialogSelectedItem = null;

				this.$refs.positionDialog.show();
			},
			savePosition() {
				if (this.positionDialogSelectedItem == null) {
					this.assignmentPositions.push({
						articlename: this.positionDialog.articlename,
						count: this.positionDialog.count,
						price: this.positionDialog.price,
						ek: this.positionDialog.ek,
						vat: this.positionDialog.vat,
						discount: this.positionDialog.discount,
					});
				} else {
					this.positionDialogSelectedItem.articlename =
						this.positionDialog.articlename;
					this.positionDialogSelectedItem.count =
						this.positionDialog.count;
					this.positionDialogSelectedItem.price =
						this.positionDialog.price;
					this.positionDialogSelectedItem.ek = this.positionDialog.ek;
					this.positionDialogSelectedItem.vat =
						this.positionDialog.vat;
					this.positionDialogSelectedItem.discount =
						this.positionDialog.discount;
				}

				this.$refs.positionDialog.hide();
			},
			editPosition(position) {
				this.positionDialogSelectedItem = position;
				this.positionDialog.articlename =
					this.positionDialogSelectedItem.articlename;
				this.positionDialog.count =
					this.positionDialogSelectedItem.count;
				this.positionDialog.price =
					this.positionDialogSelectedItem.price;
				this.positionDialog.ek = this.positionDialogSelectedItem.ek;
				this.positionDialog.vat = this.positionDialogSelectedItem.vat;
				this.positionDialog.discount =
					this.positionDialogSelectedItem.discount;
				this.$refs.positionDialog.show();
			},
			deletePosition() {
				let index = null;
				for (let i = 0; i < this.assignmentPositions.length; i++) {
					if (
						this.assignmentPositions[i] ==
						this.positionDialogSelectedItem
					) {
						index = i;
					}
				}
				if (this.assignmentPositions[index].id !== undefined) {
					this.deletedAssignmentPositions.push(
						this.assignmentPositions[index].id
					);
				}
				this.assignmentPositions.splice(index, 1);
				this.$refs.positionDialog.hide();
			},
		},
	};
</script>

<style></style>
