import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useInvoicesStore = defineStore('invoices', {
	state: () => ({
		invoices: null,
	}),
	actions: {
		async getInvoices() {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url + 'invoices';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						customer: 1,
						invoicepositions: 1,
					},
				})
				.then((response) => {
					that.invoices = response.data;
				});

			return that.invoices;
		},
		async getInvoice(invoiceId) {
			let invoice = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'invoices/' +
				invoiceId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					invoice = response.data;
				});

			return invoice;
		},
		async editInvoice({ invoiceData, invoiceId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let invoice = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'invoices/' +
				invoiceId;
			let that = this;
			await axios
				.put(url, invoiceData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					invoice = response.data;

					// update invoice data in localstorage
					let localInvoices = that.invoices;
					if (localInvoices != null) {
						for (let i = 0; i < localInvoices.length; i++) {
							if (localInvoices[i].id == invoice.id) {
								localInvoices[i].number = invoice.number;
								localInvoices[i].invoicedate =
									invoice.invoicedate;
								localInvoices[i].billingdate =
									invoice.billingdate;
								localInvoices[i].status = invoice.status;
							}
						}
					}
				});

			return invoice;
		},
		async sendInvoiceMail({ invoiceId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'invoices/' +
				invoiceId +
				'/sendMail';
			await axios
				.post(
					url,
					{},
					{
						headers: {
							Authorization: jwt,
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					return response.data;
				});
		},
		async invoiceGenerateReceipt({ invoiceId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let receipt = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'invoices/' +
				invoiceId +
				'/generateReceipt';
			await axios
				.post(
					url,
					{},
					{
						headers: {
							Authorization: jwt,
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					receipt = response.data;
				});

			return receipt;
		},
		async invoiceStorno({ invoiceId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let receipt = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'invoices/' +
				invoiceId +
				'/storno';
			await axios
				.post(
					url,
					{},
					{
						headers: {
							Authorization: jwt,
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					receipt = response.data;
				});

			return receipt;
		},
		async invoiceGetFirstReminder({ invoiceId, reminderData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let reminder = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'invoices/' +
				invoiceId +
				'/getBillingReminder';
			await axios
				.post(url, reminderData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					reminder = response.data;
				});

			return reminder;
		},
		async invoiceRegenerateFile({ invoiceId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let result = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'invoices/' +
				invoiceId +
				'/generate';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					result = response.data;
				});

			return result;
		},
	},
});
