<template>
	<router-link to="/customers/add">Add</router-link>

	<w-datatable
		:headers="headers"
		:items="customers"
		:actions="actions"
		:showedColumns="showedColumns"
		@view="handleCustomerView"
		@edit="handleCustomerEdit"
	/>
</template>

<script>
	import { WDatatable } from '@wirecore/wirecomponents';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'Home',
		components: {
			WDatatable,
		},
		methods: {
			handleCustomerView(data) {
				this.$router.push({
					name: 'CustomersView',
					params: { id: data.id },
				});
			},
			handleCustomerEdit(data) {
				this.$router.push({
					name: 'CustomersEdit',
					params: { id: data.id },
				});
			},
		},
		mounted() {
			this.$nextTick(function () {
				const customersStore = useCustomersStore();
				customersStore.getCustomers().then((response) => {
					this.customers = response;
				});
			});
		},
		data() {
			return {
				dialogActive: false,
				actions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
				],
				showedColumns: [
					'number',
					'lastname',
					'organisation',
					'firstname',
				],
				headers: [
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Name',
						value: 'name',
					},
					{
						text: 'Organisation',
						value: 'organisation',
					},
					{
						text: 'Firstname',
						value: 'firstname',
					},
				],
				customers: [],
			};
		},
	};
</script>

<style></style>
