<template>
	<p>Umsatzstatistik</p>
	<BarChart :chartData="this.chartData" />
	<p>GuV Statistik</p>
	<BarChart :chartData="this.guvData" />
</template>

<script>
	import { BarChart } from 'vue-chart-3';
	import { Chart, registerables } from 'chart.js';
	import moment from 'moment';
	import { useInvoicesStore } from '../store/invoices';
	import { useAccountsStore } from '../store/accounts';

	Chart.register(...registerables);

	export default {
		name: 'Home',
		components: { BarChart },
		mounted() {
			this.$nextTick(function () {
				const invoicesStore = useInvoicesStore();
				invoicesStore.getInvoices().then((response) => {
					// calculate pricings
					let months = [];
					for (let i = 1; i < 24; i++) {
						let monthKey = moment()
							.startOf('month')
							.subtract(i, 'M')
							.format('YYYY-MM');
						months[monthKey] = 0;
					}

					// filter last 12 months
					const beginMonthUnix = moment()
						.startOf('month')
						.subtract(23, 'M')
						.unix();
					response.forEach(function (value) {
						if (moment(value.invoicedate).unix() > beginMonthUnix) {
							let month = moment(value.invoicedate).format(
								'YYYY-MM'
							);
							months[month] += value.price.netto;
						}
					});

					// build dataset
					let labels = [];
					let data = [];
					for (const [key, value] of Object.entries(months)) {
						labels.push(
							moment(key).format('MMMM') +
								' ' +
								moment(key).format('YYYY')
						);
						data.push(value);
					}

					labels = labels.reverse();
					data = data.reverse();

					this.chartData = {
						labels: labels,
						datasets: [
							{
								data: data,
								backgroundColor: '#0079AF',
							},
						],
					};
				});

				const accountsStore = useAccountsStore();
				accountsStore.getAccounts().then((response) => {
					// calculate pricings
					let incomeMonths = [];
					let outputMonths = [];
					for (let i = 1; i < 24; i++) {
						let monthKey = moment()
							.startOf('month')
							.subtract(i, 'M')
							.format('YYYY-MM');
						incomeMonths[monthKey] = 0;
						outputMonths[monthKey] = 0;
					}

					// filter last 12 months
					const beginMonthUnix = moment()
						.startOf('month')
						.subtract(23, 'M')
						.unix();
					response.forEach(function (value) {
						value.Transactions.forEach(function (transactionValue) {
							if (
								moment(transactionValue.booking).unix() >
								beginMonthUnix
							) {
								let month = moment(
									transactionValue.booking
								).format('YYYY-MM');
								if (transactionValue.amount < 0) {
									outputMonths[month] -=
										transactionValue.amount;
								} else {
									incomeMonths[month] +=
										transactionValue.amount;
								}
							}
						});
					});

					// build dataset
					let labels = [];
					let incomeData = [];
					for (const [key, value] of Object.entries(incomeMonths)) {
						labels.push(
							moment(key).format('MMMM') +
								' ' +
								moment(key).format('YYYY')
						);
						incomeData.push(value);
					}
					let outputData = [];
					for (const [key, value] of Object.entries(outputMonths)) {
						console.log(key);
						outputData.push(value);
					}

					labels = labels.reverse();
					incomeData = incomeData.reverse();
					outputData = outputData.reverse();

					this.guvData = {
						labels: labels,
						datasets: [
							{
								label: 'Income',
								data: incomeData,
								backgroundColor: '#0079AF',
							},
							{
								label: 'Output',
								data: outputData,
								backgroundColor: '#ff564a',
							},
						],
					};
				});
			});
		},
		data() {
			return {
				chartData: {},
				guvData: {},
			};
		},
	};
</script>

<style></style>
