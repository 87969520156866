<template>
	<router-link to="/assignments/add">Add</router-link>

	<w-datatable
		:headers="headers"
		:items="assignments"
		:actions="actions"
		:showedColumns="showedColumns"
		@view="handleAssignmentView"
		@edit="handleAssignmentEdit"
		items_per_page="100"
	/>
</template>

<script>
	import { WDatatable } from '@wirecore/wirecomponents';
	import { useAssignmentsStore } from './../../store/assignments';

	export default {
		name: 'AssignmentsIndex',
		components: {
			WDatatable,
		},
		methods: {
			showDialog() {
				this.$refs.dialog.show();
			},
			handleAssignmentView(data) {
				this.$router.push({
					name: 'AssignmentsView',
					params: { id: data.id },
				});
			},
			handleAssignmentEdit(data) {
				this.$router.push({
					name: 'AssignmentsEdit',
					params: { id: data.id },
				});
			},
		},
		mounted() {
			this.$nextTick(function () {
				const assignmentsStore = useAssignmentsStore();
				assignmentsStore.getAssignments().then((response) => {
					this.assignments = response;

					response.forEach(function (value) {
						value.customernumber = value.Customer.number;
						if (value.Customer.type == 'b2b') {
							value.customername = value.Customer.organisation;
						} else {
							value.customername =
								value.Customer.firstname +
								' ' +
								value.Customer.lastname;
						}
					});
				});
			});
		},
		data() {
			return {
				dialogActive: false,
				showedColumns: [
					'status',
					'number',
					'starttime',
					'customernumber',
					'customername',
					'netto',
				],
				actions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
				],
				headers: [
					{
						text: 'Status',
						value: 'status',
					},
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Starttime',
						value: 'starttime',
					},
					{
						text: 'Customernumber',
						value: 'customernumber',
					},
					{
						text: 'Customer',
						value: 'customer',
					},
					{
						text: 'Netto',
						value: 'netto',
					},
				],
				assignments: [],
			};
		},
	};
</script>

<style></style>
