<template>
	<p>Accounts Add</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="account.bankname" type="text"
						>Bankname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="account.iban" type="text">IBAN</w-input>
				</div>
				<div class="mb-4">
					<w-input v-model="account.bic" type="text">BIC</w-input>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="account.owner" type="text">Owner</w-input>
				</div>
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'N26', value: 'n26' },
							{ name: 'Commerzbank', value: 'commerzbank' },
						]"
						v-model="account.banking_institution"
						>Banking Institution</w-select
					>
				</div>
				<div class="mb-4">
					<w-checkbox v-model="account.default_used"
						>Standart</w-checkbox
					>
				</div>
			</div>
		</div>
	</div>
	<w-btn @click="this.addAccount()">Hinzufügen</w-btn>
</template>

<script>
	import { WInput, WCheckbox, WBtn, WSelect } from '@wirecore/wirecomponents';
	import { useAccountsStore } from './../../store/accounts';

	export default {
		name: 'AccountsAdd',
		components: {
			WInput,
			WBtn,
			WCheckbox,
			WSelect,
		},
		data() {
			return {
				account: {
					banking_institution: '',
					bankname: '',
					iban: '',
					bic: '',
					owner: '',
					default_used: '',
				},
			};
		},
		methods: {
			addAccount() {
				if (this.account.default_used == true) {
					this.account.default_used = 1;
				} else {
					this.account.default_used = 0;
				}

				const accountsStore = useAccountsStore();
				accountsStore.addAccount(this.account).then(() => {
					this.$router.push({ name: 'AccountsIndex' });
				});
			},
		},
	};
</script>

<style></style>
