<template>
	<p>Articles View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Einname', value: 'income' },
							{ name: 'Ausgabe', value: 'output' },
						]"
						v-model="receipt.type"
						>Type</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="receipt.number" type="text"
						>Belegnummer</w-input
					>
				</div>
				<div class="mb-4">
					<w-datepicker v-model="receipt.date">Date</w-datepicker>
				</div>
				<div class="mb-4">
					<w-select
						:options="this.receiptmanufactors"
						v-model="receipt.manufactor_id"
						>Manufactor</w-select
					>
				</div>
				<div class="mb-4">
					<w-textarea v-model="receipt.description"
						>Description</w-textarea
					>
				</div>
				<div class="mb-4">
					<w-select
						:options="this.receiptcategories"
						v-model="receipt.category_id"
						>Category</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="receipt.vat" type="text">Vat</w-input>
				</div>
				<div class="mb-4">
					<w-input v-model="receipt.amount" type="text"
						>Amount</w-input
					>
				</div>
				<div class="mb-4">
					<label
						class="block text-gray-700 text-sm font-bold mb-2"
						for="username"
					>
						File
					</label>
					<input
						class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						id="username"
						type="file"
						@change="fileInputHandler"
						:value="fileInput"
					/>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-1">
			<template v-if="showedPdf == null">
				<p>Loading ...</p>
			</template>
			<template v-else>
				<a :href="showedPdf" download="file.pdf">Download</a>
				<a
					v-if="stornoPdf != null"
					:href="stornoPdf"
					download="stornofile.pdf"
					>Download Storno</a
				>
				<div v-if="pageCount > 1">
					<button @click="currentPage--">Vorherige Seite</button>
					<button @click="currentPage++">Nächste Seite</button>
				</div>
				<div>
					{{ currentPage }} / {{ pageCount }}
					<pdf
						:src="showedPdf"
						:page="currentPage"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					/>
				</div>
			</template>
		</div>
	</div>
	<w-btn @click="this.addReceipt()">Hinzufügen</w-btn>
</template>

<script>
	import pdfvuer from 'pdfvuer';
	import moment from 'moment';
	import {
		WSelect,
		WInput,
		WDatepicker,
		WTextarea,
		WBtn,
	} from '@wirecore/wirecomponents';
	import { useReceiptsStore } from './../../store/receipts';

	export default {
		name: 'ReceiptsAdd',
		components: {
			pdf: pdfvuer,
			WSelect,
			WInput,
			WDatepicker,
			WTextarea,
			WBtn,
		},
		data() {
			return {
				receiptmanufactors: [],
				receiptcategories: [],
				fileInput: '',
				receipt: {
					amount: '',
					category_id: '',
					date: '',
					description: '',
					file: '',
					id: '',
					invoice_id: '',
					manufactor_id: '',
					number: '',
					type: '',
					vat: '',
				},
				currentPage: 0,
				pageCount: 0,
				showedPdf: null,
				stornoPdf: null,
			};
		},
		mounted() {
			const receiptsStore = useReceiptsStore();
			receiptsStore.getReceiptcategories().then((response) => {
				for (let i = 0; i < response.length; i++) {
					this.receiptcategories.push({
						name: response[i].name,
						value: response[i].id,
					});
				}
			});

			receiptsStore
				.getReceiptmanufactors({ receipts: 0 })
				.then((response) => {
					for (let i = 0; i < response.length; i++) {
						this.receiptmanufactors.push({
							name: response[i].name,
							value: response[i].id,
						});
					}
				});

			const receiptId = this.$route.params.id;
			const manufactorId = this.$route.params.manufactorId;

			let that = this;
			receiptsStore
				.getReceipt({
					manufactorId: manufactorId,
					receiptId: receiptId,
				})
				.then(async function (response) {
					that.receipt = response;

					// convert start and end time to values that are can showing in fields
					that.receipt.date = moment(that.receipt.date).format(
						'YYYY-MM-DDTHH:mm'
					);

					that.showedPdf =
						'data:application/pdf;base64,' + that.receipt.file;

					// TODO implement better navigation. look at https://github.com/arkokoley/pdfvuer
					that.currentPage = 1;

					const reader = new FileReader();
					const base64Response = await fetch(
						`data:application/pdf;base64,${that.receipt.file}`
					);
					const blob = await base64Response.blob();
					reader.readAsBinaryString(blob);
					reader.onloadend = () => {
						const count = reader.result.match(
							/\/Type[\s]*\/Page[^s]/g
						).length;
						that.pageCount = count;
					};
				});
		},
		methods: {
			fileInputHandler(event) {
				//Read File
				var selectedFile = event.target.files;
				//Check File is not Empty
				if (selectedFile.length > 0) {
					// Select the very first file from list
					var fileToLoad = selectedFile[0];
					// FileReader function for read the file.
					var fileReader = new FileReader();
					var base64;
					// Onload of file read the file content
					let that = this;
					fileReader.onload = async function (fileLoadedEvent) {
						base64 = fileLoadedEvent.target.result;
						// Print data in console
						that.receipt.file = base64.split(',')[1];
						that.showedPdf = base64;
						that.currentPage = 1;

						const reader = new FileReader();
						const base64Response = await fetch(
							`data:application/pdf;base64,${that.receipt.file}`
						);
						const blob = await base64Response.blob();
						reader.readAsBinaryString(blob);
						reader.onloadend = () => {
							const count = reader.result.match(
								/\/Type[\s]*\/Page[^s]/g
							).length;
							that.pageCount = count;
						};
					};
					// Convert data to base64
					fileReader.readAsDataURL(fileToLoad);
				}
			},
			addReceipt() {
				let receiptData = this.receipt;

				receiptData.amount = parseFloat(
					receiptData.amount.replace(',', '.')
				);
				receiptData.vat = parseInt(receiptData.vat);

				const receiptsStore = useReceiptsStore();
				receiptsStore
					.editReceipt({
						receiptmanufactorId: this.receipt.manufactor_id,
						receiptId: this.receipt.id,
						receiptData: receiptData,
					})
					.then(() => {
						this.$router.push({ name: 'ReceiptsIndex' });
					});
			},
		},
	};
</script>

<style></style>
