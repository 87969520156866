<template>
	<w-sidebar :items="[
      { text: 'Dashboard', link: '/' },
      { text: 'Kunden', link: '/customers' },
      { text: 'Artikel', link: '/articles' },
      { text: 'Kostenvoranschläge', link: '/costestimates' },
      { text: 'Aufträge', link: '/assignments' },
      { text: 'Verträge', link: '/contracts' },
      { text: 'Rechnungen', link: '/invoices' },
      { text: 'Belege', link: '/receipts' },
      { text: 'Belegstatistik', link: '/receipts/statistics' },
      { text: 'Konten', link: '/accounts' },
      { text: 'Bankvorgänge', link: '/receipts/notAssigned' },
      { text: 'Export', link: '/export' }
    ]" 
  />
</template>

<script>

import { WSidebar } from '@wirecore/wirecomponents';

export default {
	name: 'Sidebar',
  components: {
    WSidebar
  }
}
</script>