<template>
	<p>Customer Edit</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'B2B', value: 'b2b' },
							{ name: 'B2C', value: 'b2c' },
						]"
						v-model="customer.type"
						>Type</w-select
					>
				</div>
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Herr', value: 'Herr' },
							{ name: 'Frau', value: 'Frau' },
						]"
						v-model="customer.salutation"
						>Salutation</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.firstname" type="text"
						>Firstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.lastname" type="text"
						>Lastname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.ustid" type="text"
						>UST-ID</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="customer.position" type="text"
						>Position</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.organisation" type="text"
						>Organisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="customer.taxnumber" type="text"
						>Taxnumber</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<w-btn @click="this.openAdresseDialog()">Add Adresse</w-btn>
		<w-dialog ref="addresseDialog">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Private', value: 'private' },
							{ name: 'Business', value: 'business' },
						]"
						v-model="addresseDialog.type"
						>Type</w-select
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.street" type="text"
						>Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.plz" type="text"
						>Plz</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.city" type="text"
						>City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="addresseDialog.country" type="text"
						>Country</w-input
					>
				</div>
				<w-btn @click="saveAddresse()">Save</w-btn>
			</div>
		</w-dialog>
		<w-datatable
			:headers="addresseHeaders"
			:items="customer.Addresses"
			:actions="addresseActions"
			:showedColumns="['type', 'street', 'plz', 'city', 'country']"
			@edit="handleAddresseEdit"
			@delete="handleAddresseDelete"
		/>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<w-btn @click="this.openPhonenumberDialog()">Add Number</w-btn>
				<w-dialog ref="phonenumberDialog">
					<div class="container mx-auto">
						<div class="mb-4">
							<w-select
								:options="[
									{ name: 'Private', value: 'private' },
									{ name: 'Business', value: 'business' },
								]"
								v-model="phonenumberDialog.type"
								>Type</w-select
							>
						</div>
						<div class="mb-4">
							<w-input
								v-model="phonenumberDialog.number"
								type="text"
								>Number</w-input
							>
						</div>
						<w-btn @click="savePhonenumber()">Save</w-btn>
					</div>
				</w-dialog>
				<w-datatable
					:headers="phonenumbersHeaders"
					:items="customer.Phonenumbers"
					:actions="phonenumberActions"
					@edit="handlePhonenumberEdit"
					@delete="handlePhonenumberDelete"
					:showedColumns="['type', 'number']"
				/>
			</div>
			<div class="container mx-auto">
				<w-btn @click="this.openMailDialog()">Add Mail</w-btn>
				<w-dialog ref="mailDialog">
					<div class="container mx-auto">
						<div class="mb-4">
							<w-select
								:options="[
									{ name: 'Private', value: 'private' },
									{ name: 'Business', value: 'business' },
								]"
								v-model="mailDialog.type"
								>Type</w-select
							>
						</div>
						<div class="mb-4">
							<w-input v-model="mailDialog.mail" type="text"
								>Mail</w-input
							>
						</div>
						<w-btn @click="saveMail()">Save</w-btn>
					</div>
				</w-dialog>
				<w-datatable
					:headers="mailsHeaders"
					:items="customer.Mails"
					:actions="mailsActions"
					@edit="handleMailEdit"
					@delete="handleMailDelete"
					:showedColumns="['type', 'mail']"
				/>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="m-4">
			<w-btn
				@click="this.editCustomer()"
				:disabled="button.disabled"
				:loading="button.loading"
				>Bearbeiten</w-btn
			>
		</div>
	</div>
</template>

<script>
	import {
		WSelect,
		WInput,
		WDialog,
		WBtn,
		WDatatable,
	} from '@wirecore/wirecomponents';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'CustomerEdit',
		components: {
			WSelect,
			WInput,
			WDialog,
			WBtn,
			WDatatable,
		},
		data() {
			return {
				button: {
					loading: false,
					disabled: false,
				},
				customerId: null,
				customer: {
					number: '',
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					position: '',
					organisation: '',
					taxnumber: '',
					ustid: '',
					Phonenumbers: [],
					Mails: [],
					Addresses: [],
				},
				addresseDialog: {
					id: '',
					type: '',
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				addresseDialogMode: '',
				addresseActions: [
					{
						text: 'Edit',
						emitName: 'edit',
					},
					{
						text: 'Delete',
						emitName: 'delete',
					},
				],
				addresseHeaders: [
					{
						text: 'Type',
						value: 'type',
					},
					{
						text: 'Straße',
						value: 'street',
					},
					{
						text: 'PLZ',
						value: 'plz',
					},
					{
						text: 'Stadt',
						value: 'city',
					},
					{
						text: 'Land',
						value: 'country',
					},
				],
				phonenumberDialog: {
					id: '',
					type: '',
					number: '',
				},
				phonenumberDialogMode: '',
				phonenumberActions: [
					{
						text: 'Edit',
						emitName: 'edit',
					},
					{
						text: 'Delete',
						emitName: 'delete',
					},
				],
				phonenumbersHeaders: [
					{
						text: 'Type',
						value: 'type',
					},
					{
						text: 'Number',
						value: 'number',
					},
				],
				mailDialog: {
					id: '',
					type: '',
					mail: '',
				},
				mailDialogMode: '',
				mailsActions: [
					{
						text: 'Edit',
						emitName: 'edit',
					},
					{
						text: 'Delete',
						emitName: 'delete',
					},
				],
				mailsHeaders: [
					{
						text: 'Type',
						value: 'type',
					},
					{
						text: 'Mail',
						value: 'mail',
					},
				],
			};
		},
		mounted() {
			const customerId = this.$route.params.id;
			this.customerId = customerId;

			const customersStore = useCustomersStore();
			customersStore.getCustomer(customerId).then((response) => {
				this.customer = response;
			});
		},
		methods: {
			handleAddresseEdit(data) {
				this.addresseDialogMode = 'edit';
				this.addresseDialog = data;
				this.$refs.addresseDialog.show();
			},
			handleAddresseDelete(data) {
				this.addresseDialogMode = 'delete';
				this.addresseDialog = data;
				if (confirm('Are you sure you want to delete this addresse?')) {
					this.saveAddresse();
				}
			},
			handleMailEdit(data) {
				this.mailDialogMode = 'edit';
				this.mailDialog = data;
				this.$refs.mailDialog.show();
			},
			handleMailDelete(data) {
				this.mailDialogMode = 'delete';
				this.mailDialog = data;
				if (
					confirm(
						'Are you sure you want to delete this mail addresse?'
					)
				) {
					this.saveMail();
				}
			},
			handlePhonenumberEdit(data) {
				this.phonenumberDialogMode = 'edit';
				this.phonenumberDialog = data;
				this.$refs.phonenumberDialog.show();
			},
			handlePhonenumberDelete(data) {
				this.phonenumberDialogMode = 'delete';
				this.phonenumberDialog = data;
				if (
					confirm('Are you sure you want to delete this phonenumber?')
				) {
					this.savePhonenumber();
				}
			},
			editCustomer() {
				this.button.disabled = true;
				this.button.loading = true;

				const customersStore = useCustomersStore();
				customersStore
					.editCustomer({
						customerId: this.customerId,
						customerData: this.customer,
					})
					.then((response) => {
						const customerId = response.id;

						// Addresses
						this.customer.Addresses.forEach((addresse) => {
							if (addresse.action !== undefined) {
								if (addresse.action == 'add') {
									customersStore.addCustomerAddresse({
										addresseData: addresse,
										customerId: customerId,
									});
								} else if (addresse.action == 'edit') {
									customersStore.editCustomerAddresse({
										addresseData: addresse,
										customerId: customerId,
										addresseId: addresse.id,
									});
								} else if (addresse.action == 'delete') {
									customersStore.deleteCustomerAddresse({
										customerId: customerId,
										addresseId: addresse.id,
									});
								}
							}
						});

						// Phonenumbers
						this.customer.Phonenumbers.forEach((number) => {
							if (number.action !== undefined) {
								if (number.action == 'add') {
									customersStore.addCustomerPhonenumber({
										phonenumberData: number,
										customerId: customerId,
									});
								} else if (number.action == 'edit') {
									customersStore.editCustomerPhonenumber({
										phonenumberData: number,
										customerId: customerId,
										phonenumberId: number.id,
									});
								} else if (number.action == 'delete') {
									customersStore.deleteCustomerPhonenumber({
										customerId: customerId,
										phonenumberId: number.id,
									});
								}
							}
						});

						// Mails
						this.customer.Mails.forEach((mail) => {
							if (mail.action !== undefined) {
								if (mail.action == 'add') {
									customersStore.addCustomerMail({
										mailData: mail,
										customerId: customerId,
									});
								} else if (mail.action == 'edit') {
									customersStore.editCustomerMail({
										mailData: mail,
										customerId: customerId,
										mailId: mail.id,
									});
								} else if (mail.action == 'delete') {
									customersStore.deleteCustomerMail({
										customerId: customerId,
										mailId: mail.id,
									});
								}
							}
						});

						this.$router.push({ name: 'Customers' });
					});
			},
			openAdresseDialog() {
				this.addresseDialogMode = 'add';
				this.addresseDialog = {};
				this.$refs.addresseDialog.show();
			},
			saveAddresse() {
				if (this.addresseDialogMode == 'add') {
					this.customer.Addresses.push({
						id: 0,
						type: this.addresseDialog.type,
						street: this.addresseDialog.street,
						plz: this.addresseDialog.plz,
						city: this.addresseDialog.city,
						country: this.addresseDialog.country,
						action: 'add',
					});
				} else if (this.addresseDialogMode == 'edit') {
					this.addresseDialog.action = 'edit';
				} else if (this.addresseDialogMode == 'delete') {
					this.addresseDialog.action = 'delete';
				}
				this.$refs.addresseDialog.hide();
			},
			openPhonenumberDialog() {
				this.phonenumberDialogMode = 'add';
				this.phonenumberDialog = {};
				this.$refs.phonenumberDialog.show();
			},
			savePhonenumber() {
				if (this.phonenumberDialogMode == 'add') {
					this.customer.Phonenumbers.push({
						id: 0,
						type: this.phonenumberDialog.type,
						number: this.phonenumberDialog.number,
						action: 'add',
					});
				} else if (this.phonenumberDialogMode == 'edit') {
					this.phonenumberDialog.action = 'edit';
				} else if (this.phonenumberDialogMode == 'delete') {
					this.phonenumberDialog.action = 'delete';
				}
				this.$refs.phonenumberDialog.hide();
			},
			openMailDialog() {
				this.mailDialogMode = 'add';
				this.mailDialog = {};
				this.$refs.mailDialog.show();
			},
			saveMail() {
				if (this.mailDialogMode == 'add') {
					this.customer.Mails.push({
						id: 0,
						type: this.mailDialog.type,
						mail: this.mailDialog.mail,
						action: 'add',
					});
				} else if (this.mailDialogMode == 'edit') {
					this.mailDialog.action = 'edit';
				} else if (this.mailDialogMode == 'delete') {
					this.mailDialog.action = 'delete';
				}
				this.$refs.mailDialog.hide();
			},
		},
	};
</script>

<style></style>
