import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useAccountsStore = defineStore('accounts', {
	state: () => ({
		accounts: [],
	}),
	actions: {
		async getAccounts() {
			if (this.accounts.length == 0) {
				const auth = useAuthStore();
				const jwt = auth.jwt;

				const url =
					managerpanel_accountingmanagement_backend_url +
					'accounts?transactions=1';
				let that = this;
				await axios
					.get(url, {
						headers: {
							Authorization: jwt,
						},
					})
					.then((response) => {
						that.accounts = response.data;
					});
			}
			return this.accounts;
		},
		async getAccount(accountId) {
			let account = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'accounts/' +
				accountId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						transactions: 1,
					},
				})
				.then((response) => {
					account = response.data;
				});

			return account;
		},
		async addAccount(accountData) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let account = null;

			const url =
				managerpanel_accountingmanagement_backend_url + 'accounts';
			await axios
				.post(url, accountData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					account = response.data;
				});

			return account;
		},
		async editAccount({ accountId, accountData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let account = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'accounts/' +
				accountId;
			await axios
				.put(url, accountData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					account = response.data;
				});

			return account;
		},
	},
});
