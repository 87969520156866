import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
	state: () => ({
		jwt: null,
	}),
	actions: {
		setJwtToken(token) {
			this.jwt = token;
		},
	},
});
