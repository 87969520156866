<template>
	<router-link to="/invoices/add">Add</router-link>

	<w-datatable
		:headers="headers"
		:items="invoices"
		:actions="actions"
		:showedColumns="showedColumns"
		@view="handleInvoiceView"
		@edit="handleInvoiceEdit"
		@download="handleInvoiceDownload"
		items_per_page="100"
	/>
</template>

<script>
	import { WDatatable } from '@wirecore/wirecomponents';
	import { useInvoicesStore } from './../../store/invoices';

	export default {
		name: 'Home',
		components: {
			WDatatable,
		},
		methods: {
			handleInvoiceView(data) {
				this.$router.push({
					name: 'InvoicesView',
					params: { id: data.id },
				});
			},
			handleInvoiceEdit(data) {
				this.$router.push({
					name: 'InvoicesEdit',
					params: { id: data.id },
				});
			},
			handleInvoiceDownload(data) {
				const invoicesStore = useInvoicesStore();
				invoicesStore.getInvoice(data.id).then((response) => {
					const linkSource = `data:application/pdf;base64,${response.file}`;
					const downloadLink = document.createElement('a');
					const fileName = response.number + '.pdf';

					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
				});
			},
		},
		mounted() {
			this.$nextTick(function () {
				const invoicesStore = useInvoicesStore();
				invoicesStore.getInvoices().then((response) => {
					for (let i = 0; i < response.length; i++) {
						// create customername
						if (response[i].Customer.type == 'b2b') {
							response[i].CustomerName =
								response[i].Customer.organisation;
						} else {
							response[i].CustomerName =
								response[i].Customer.firstname +
								' ' +
								response[i].Customer.lastname;
						}

						// parse status
						if (response[i].status == 'open') {
							response[i].status = 'Nicht bezahlt';
						} else {
							response[i].status = 'Bezahlt';
						}
						if (response[i].storno == true) {
							// update billingdate and status by storno invoices
							response[i].status = 'Storniert';
							response[i].billingdate = '---';
						}

						// create brutto
						if (response[i].price != null) {
							response[i].brutto =
								response[i].price.brutto.toFixed(2).toString() +
								' €';
						}
					}

					this.invoices = response;
				});
			});
		},
		data() {
			return {
				dialogActive: false,
				showedColumns: [
					'status',
					'number',
					'brutto',
					'invoicedate',
					'billingdate',
					'CustomerName',
				],
				actions: [
					{
						text: 'View',
						emitName: 'view',
					},
					{
						text: 'Edit',
						emitName: 'edit',
					},
					{
						text: 'Download',
						emitName: 'download',
					},
				],
				headers: [
					{
						text: 'Status',
						value: 'status',
					},
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Brutto €',
						value: 'brutto',
					},
					{
						text: 'Rechnungsdatum',
						value: 'invoiceDate',
					},
					{
						text: 'Bezahlt am',
						value: 'billingdate',
					},
					{
						text: 'Customer',
						value: 'CustomerName',
					},
				],
				invoices: [],
			};
		},
	};
</script>

<style></style>
