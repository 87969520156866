import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useAssignmentsStore = defineStore('assignments', {
	state: () => ({
		assignments: [],
	}),
	actions: {
		async getAssignments() {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments?customer=1';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					that.assignments = response.data;
				});

			return this.assignments;
		},
		async getAssignment(assignmentId) {
			let assignment = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						address: 1,
						customer: 1,
						assignmentpositions: 1,
					},
				})
				.then((response) => {
					assignment = response.data;
				});

			return assignment;
		},
		async getAssignmentDocuments(assignmentId) {
			let documents = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId +
				'/documents';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					documents = response.data;
				});

			return documents;
		},
		async addAssignmentDocument({ assignmentId, documentData }) {
			let document = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId +
				'/documents';

			await axios
				.post(url, documentData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					document = response.data;
				});

			return document;
		},
		async addAssignment(assignmentData) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let assignment = null;

			const url =
				managerpanel_accountingmanagement_backend_url + 'assignments';
			await axios
				.post(url, assignmentData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					assignment = response.data;
				});

			return assignment;
		},
		async editAssignment({ assignmentId, assignmentData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let assignment = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId;
			await axios
				.put(url, assignmentData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					assignment = response.data;
				});

			return assignment;
		},
		async getAssignmentDeliveryNote(assignmentId) {
			let deliverynote = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId +
				'/getDeliveryNote';

			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
					params: {
						addresse: 1,
						customer: 1,
						positions: 1,
					},
				})
				.then((response) => {
					deliverynote = response.data;
				});

			return deliverynote;
		},
		async addAssignmentPosition({ positionData, assignmentId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId +
				'/assignmentpositions';
			await axios
				.post(url, positionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async editAssignmentPosition({
			positionData,
			assignmentId,
			positionId,
		}) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId +
				'/assignmentpositions/' +
				positionId;
			await axios
				.put(url, positionData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async deleteAssignmentPosition({ assignmentId, positionId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId +
				'/assignmentpositions/' +
				positionId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
		async assignmentGenInvoice({ assignmentId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let invoice = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'assignments/' +
				assignmentId +
				'/genInvoice';
			await axios
				.post(
					url,
					{
						type: 'normal',
					},
					{
						headers: {
							Authorization: jwt,
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					invoice = response.data;
				});

			return invoice;
		},
	},
});
