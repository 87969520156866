<template>
	<p>Articles View</p>
	<div class="container mx-auto">
		<div class="container mx-auto">
			<div class="grid grid-cols-2">
				<div class="mb-4 text-left">
					<w-btn
						v-if="this.prevId != null"
						@click="this.openOtherInvoice(this.prevId)"
						>Vorherige</w-btn
					>
				</div>
				<div class="mb-4 text-right">
					<w-btn
						v-if="this.nextId != null"
						@click="this.openOtherInvoice(this.nextId)"
						>Nächste</w-btn
					>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="invoice.number" disabled type="text"
						>Invoicenumber</w-input
					>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-1">
			<template v-if="showedPdf == null">
				<p>Loading ...</p>
			</template>
			<template v-else>
				<w-btn class="mb-2" @click="this.sendMail()"
					>E-Mail senden</w-btn
				>
				<w-btn
					class="mb-2"
					@click="this.generateReceipt()"
					:loading="generateReceiptButtonLoadingState"
					>Beleg erstellen</w-btn
				>
				<w-btn class="mb-2" @click="this.storno()"
					>Rechnung stornieren</w-btn
				>
				<w-btn class="mb-2" @click="this.openReminderDialog()"
					>Zahlungserinnerung / Mahnung erstellen</w-btn
				>
				<w-btn class="mb-2" @click="this.generateInvoice()"
					>Rechnung neu generieren</w-btn
				>
				<a :href="showedPdf" download="file.pdf">Download</a>
				<a
					v-if="stornoPdf != null"
					:href="stornoPdf"
					download="stornofile.pdf"
					>Download Storno</a
				>
				<div v-if="pageCount > 1">
					<button @click="currentPage--">Vorherige Seite</button>
					<button @click="currentPage++">Nächste Seite</button>
				</div>
				<div>
					{{ currentPage }} / {{ pageCount }}
					<pdf
						:src="showedPdf"
						:page="currentPage"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					/>
				</div>
			</template>
			<w-dialog ref="reminderDialog">
				<w-select
					:options="[
						{ name: 'Zahlungserinnerung', value: 'first' },
						{ name: '1. Mahnung', value: 'second' },
						{ name: '2. Mahnung', value: 'third' },
					]"
					v-model="reminderDialog.type"
					>Type</w-select
				>
				<div
					v-if="
						reminderDialog.type == null ||
						reminderDialog.type == 'first'
					"
				>
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="reminderDialog.planned_receivedate"
						>Geplantes Empfangsdatum</w-datepicker
					>
				</div>
				<div v-if="reminderDialog.type == 'second'">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="reminderDialog.reminder_date"
						>Erinnerungsdatum</w-datepicker
					>
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="reminderDialog.planned_receivedate"
						>Geplantes Empfangsdatum</w-datepicker
					>
				</div>
				<div v-if="reminderDialog.type == 'third'">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="reminderDialog.reminder_date"
						>Erinnerungsdatum 1</w-datepicker
					>
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="reminderDialog.reminder_date_2"
						>Erinnerungsdatum 2</w-datepicker
					>
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="reminderDialog.planned_receivedate"
						>Geplantes Empfangsdatum</w-datepicker
					>
					<w-input
						v-model="reminderDialog.interest_percent"
						type="number"
						>Verzugszinsen</w-input
					>
					<w-input v-model="reminderDialog.dunning_cost" type="number"
						>Mahngebühren</w-input
					>
				</div>
				<w-btn class="mb-2" @click="this.getReminder()">Download</w-btn>
			</w-dialog>
		</div>
	</div>
</template>

<script>
	import pdfvuer from 'pdfvuer';
	import {
		WInput,
		WBtn,
		WDialog,
		WDatepicker,
	} from '@wirecore/wirecomponents';
	import { useToast } from 'vue-toastification';
	import { useInvoicesStore } from './../../store/invoices';

	export default {
		name: 'InvoicesView',
		components: {
			pdf: pdfvuer,
			WBtn,
			WInput,
			WDialog,
			WDatepicker,
		},
		data() {
			return {
				invoice: {
					id: '',
					customer_id: '',
					address_id: '',
					account_id: '',
					type: '',
					billingdate: '',
					invoicedate: '',
					number: '',
					reduction: '',
					reference: '',
					status: '',
					storno: '',
					stornodate: '',
					exported: '',
					file: '',
				},
				reminderDialog: {
					type: null,
					planned_receivedate: null,
					reminder_date: null,
					reminder_date_2: null,
					interest_percent: null,
					dunning_cost: null,
				},
				currentPage: 0,
				pageCount: 0,
				showedPdf: null,
				stornoPdf: null,
				prevId: null,
				nextId: null,
				invoices: null,
				generateReceiptButtonLoadingState: false,
			};
		},
		mounted() {
			const invoiceId = this.$route.params.id;

			const invoicesStore = useInvoicesStore();
			invoicesStore.getInvoice(invoiceId).then((response) => {
				this.invoice = response;

				this.showedPdf =
					'data:application/pdf;base64,' + this.invoice.file;

				if (this.invoice.filestorno != null) {
					this.stornoPdf =
						'data:application/pdf;base64,' +
						this.invoice.filestorno;
				}

				// TODO implement better navigation. look at https://github.com/arkokoley/pdfvuer
				this.currentPage = 1;
			});

			invoicesStore.getInvoices().then((response) => {
				this.invoices = response;
				this.updatePrevNextId();
			});
		},
		methods: {
			updatePrevNextId() {
				for (let i = 0; i < this.invoices.length; i++) {
					if (this.invoices[i].id == this.$route.params.id) {
						if (this.invoices[i - 1] !== undefined) {
							this.prevId = this.invoices[i - 1].id;
						}
						if (this.invoices[i + 1] !== undefined) {
							this.nextId = this.invoices[i + 1].id;
						}
					}
				}
			},
			openOtherInvoice(id) {
				this.prevId = null;
				this.nextId = null;
				this.$router.push({ name: 'InvoicesView', params: { id: id } });

				const invoicesStore = useInvoicesStore();
				invoicesStore.getInvoice(id).then((response) => {
					this.invoice = response;

					this.showedPdf = null;
					let that = this;
					setTimeout(function () {
						that.showedPdf =
							'data:application/pdf;base64,' + that.invoice.file;

						if (that.invoice.filestornoObj != null) {
							that.stornoPdf =
								'data:application/pdf;base64,' +
								that.invoice.filestorno;
						}

						// TODO implement better navigation. look at https://github.com/arkokoley/pdfvuer
						that.currentPage = 1;

						that.updatePrevNextId();
					}, 500);
				});
			},
			sendMail() {
				const invoicesStore = useInvoicesStore();
				invoicesStore.sendInvoiceMail({ invoiceId: this.invoice.id });
			},
			generateReceipt() {
				this.generateReceiptButtonLoadingState = true;

				let that = this;
				const invoicesStore = useInvoicesStore();
				invoicesStore
					.invoiceGenerateReceipt({
						invoiceId: this.invoice.id,
					})
					.then((response) => {
						that.generateReceiptButtonLoadingState = false;

						// Get toast interface
						const toast = useToast();

						// or with options
						toast.success(
							'Receipt with ID ' + response.id + ' was created'
						);
					});
			},
			storno() {
				const invoicesStore = useInvoicesStore();
				invoicesStore
					.invoiceStorno({ invoiceId: this.invoice.id })
					.then(() => {
						// Get toast interface
						const toast = useToast();

						// or with options
						toast.success('Invoice was canceled');
					});
			},
			openReminderDialog() {
				this.$refs.reminderDialog.show();
			},
			getReminder() {
				let reminderData = {
					type: this.reminderDialog.type,
					planned_receivedate:
						this.reminderDialog.planned_receivedate + ' 00:00:00',
					reminder_date:
						this.reminderDialog.reminder_date + ' 00:00:00',
					reminder_date_2:
						this.reminderDialog.reminder_date_2 + ' 00:00:00',
					interest_percent: this.reminderDialog.interest_percent,
					dunning_cost: this.reminderDialog.dunning_cost,
				};

				let that = this;
				const invoicesStore = useInvoicesStore();
				invoicesStore
					.invoiceGetFirstReminder({
						invoiceId: this.invoice.id,
						reminderData: reminderData,
					})
					.then((response) => {
						that.downloadPDF(response.file);
					});
			},
			downloadPDF(pdf) {
				const linkSource = `data:application/pdf;base64,${pdf}`;
				const downloadLink = document.createElement('a');
				const fileName = 'Reminder.pdf';

				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			},
			generateInvoice() {
				const invoicesStore = useInvoicesStore();
				invoicesStore
					.invoiceRegenerateFile({
						invoiceId: this.invoice.id,
					})
					.then((response) => {
						this.invoice = response;

						this.showedPdf =
							'data:application/pdf;base64,' + this.invoice.file;

						if (this.invoice.filestorno != null) {
							this.stornoPdf =
								'data:application/pdf;base64,' +
								this.invoice.filestorno;
						}

						// TODO implement better navigation. look at https://github.com/arkokoley/pdfvuer
						this.currentPage = 1;
					});
			},
		},
	};
</script>

<style></style>
