<template>
	<div class="grid grid-cols-2">
		<w-card headline="Receipts">
			<w-datatable
				:headers="headersReceipts"
				:items="receipts"
				:showedColumns="showedColumns"
				:show_select="dataTableShowSelect"
				@selectedChanged="updateSelectedReceipts"
				items_per_page="30"
			/>
		</w-card>
		<w-card headline="Transactions">
			<w-datatable
				:headers="headersTransactions"
				:items="transactions"
				:showedColumns="showedColumnsTransactions"
				:show_select="dataTableShowSelect"
				@selectedChanged="updateSelectedTransactions"
				items_per_page="30"
			/>
		</w-card>
	</div>

	<w-card>
		<w-btn @click="this.connectReceiptTransaction()">Zuweisen</w-btn>
	</w-card>
</template>

<script>
	import { WDatatable, WCard, WBtn } from '@wirecore/wirecomponents';
	import moment from 'moment';
	import { useReceiptsStore } from './../../store/receipts';
	import { useTransactionsStore } from './../../store/transactions';

	export default {
		name: 'ReceiptsIndex',
		components: {
			WDatatable,
			WCard,
			WBtn,
		},
		methods: {
			showDialog() {
				this.$refs.dialog.show();
			},
			handleReceiptView(data) {
				this.$router.push({
					name: 'ReceiptsView',
					params: { manufactorId: data.manufactor_id, id: data.id },
				});
			},
			handleReceiptEdit(data) {
				this.$router.push({
					name: 'ReceiptsEdit',
					params: { manufactorId: data.manufactor_id, id: data.id },
				});
			},
			connectReceiptTransaction() {
				let receipts = [];
				this.selectedReceipts.forEach(function (value) {
					receipts.push(value.id);
				});
				let transactions = [];
				this.selectedTransactions.forEach(function (value) {
					transactions.push(value.id);
				});

				let that = this;
				const receiptsStore = useReceiptsStore();
				receiptsStore
					.connectReceiptTransactions({
						receipts: receipts,
						transactions: transactions,
					})
					.then(() => {
						receipts.forEach(function (value) {
							that.receipts.forEach(function (value2, index) {
								if (value == value2.id) {
									that.receipts.splice(index, 1);
								}
							});
						});

						transactions.forEach(function (value) {
							that.transactions.forEach(function (value2, index) {
								if (value == value2.id) {
									that.transactions.splice(index, 1);
								}
							});
						});

						that.selectedReceipts = [];
						that.selectedTransactions = [];
					});
			},
			updateSelectedReceipts(value) {
				this.selectedReceipts = value;
			},
			updateSelectedTransactions(value) {
				this.selectedTransactions = value;
			},
		},
		mounted() {
			this.$nextTick(function () {
				const receiptsStore = useReceiptsStore();
				receiptsStore.getReceiptsNotAssigned().then((response) => {
					response.forEach(function (value) {
						if (value.type == 'income') {
							value.type = 'Einnahme';
						} else {
							value.type = 'Ausgabe';
						}
						value.brutto = (
							value.amount * parseFloat('1.' + value.vat)
						).toLocaleString('de-DE', {
							style: 'currency',
							currency: 'EUR',
						});
						value.date = moment(value.date).format('DD.MM.YYYY');
						value.manufactor = value.Receiptmanufactor.name;
					});
					this.receipts = response;
				});

				const transactionsStore = useTransactionsStore();
				transactionsStore
					.getTransactionsNotAssigned()
					.then((response) => {
						let transactions = this.transactions;
						response.forEach(function (value) {
							value.amount = value.amount.toLocaleString(
								'de-DE',
								{ style: 'currency', currency: 'EUR' }
							);
							value.booking = moment(value.booking).format(
								'DD.MM.YYYY'
							);
							transactions.push(value);
						});
					});
			});
		},
		data() {
			return {
				selectedTransactions: [],
				selectedReceipts: [],
				dataTableShowSelect: true,
				dialogActive: false,
				showedColumns: [
					'type',
					'date',
					'manufactor',
					'number',
					'brutto',
				],
				showedColumnsTransactions: [
					'bankname',
					'informations',
					'booking',
					'amount',
				],
				headersReceipts: [
					{
						text: 'Type',
						value: 'type',
					},
					{
						text: 'Date',
						value: 'date',
					},
					{
						text: 'Manufactor',
						value: 'manufactor',
					},
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Brutto',
						value: 'brutto',
					},
				],
				headersTransactions: [
					{
						text: 'Bankname',
						value: 'bankname',
					},
					{
						text: 'Information',
						value: 'informations',
					},
					{
						text: 'Booking',
						value: 'booking',
					},
					{
						text: 'Amount',
						value: 'amount',
					},
				],
				receipts: [],
				transactions: [],
			};
		},
	};
</script>

<style></style>
