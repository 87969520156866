import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const managerpanel_accountingmanagement_backend_url =
	process.env.VUE_APP_MANAGERPANEL_ACCOUNTINGMANAGEMENT_BACKEND_URL;

export const useArticlesStore = defineStore('articles', {
	state: () => ({
		articles: [],
	}),
	actions: {
		async getArticles() {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url + 'articles';
			let that = this;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					that.articles = response.data;
				});

			return this.articles;
		},
		async getArticle(articleId) {
			let article = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'articles/' +
				articleId;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					article = response.data;
				});

			return article;
		},
		async addArticle(articleData) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let article = null;

			const url =
				managerpanel_accountingmanagement_backend_url + 'articles';
			await axios
				.post(url, articleData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					article = response.data;
				});

			return article;
		},
		async editArticle({ articleId, articleData }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let article = null;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'articles/' +
				articleId;
			await axios
				.put(url, articleData, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					article = response.data;
				});

			return article;
		},
		async deleteArticle({ articleId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url =
				managerpanel_accountingmanagement_backend_url +
				'articles/' +
				articleId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return response.data;
				});
		},
	},
});
