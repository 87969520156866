<template>
	<p>Transaction Edit</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datetimepicker v-model="transaction.booking"
						>Booking</w-datetimepicker
					>
				</div>
				<div class="mb-4">
					<w-input v-model="transaction.informations" type="text"
						>Informations</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="transaction.amount" type="text"
						>Amount</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<w-btn @click="this.editTransaction()">Bearbeiten</w-btn>
</template>

<script>
	import moment from 'moment';
	import { WInput, WBtn, WDatetimepicker } from '@wirecore/wirecomponents';
	import { useTransactionsStore } from './../../store/transactions';

	export default {
		name: 'TransactionsEdit',
		components: {
			WInput,
			WBtn,
			WDatetimepicker,
		},
		data() {
			return {
				transaction: {
					account_id: '',
					booking: '',
					informations: '',
					amount: '',
				},
			};
		},
		mounted() {
			this.$nextTick(function () {
				const accountId = this.$route.params.account_id;
				const transactionId = this.$route.params.id;

				const transactionsStore = useTransactionsStore();
				transactionsStore
					.getTransaction({
						accountId: accountId,
						transactionId: transactionId,
					})
					.then((response) => {
						this.transaction = response;

						// convert start and end time to values that are can showing in fields
						this.transaction.booking = moment(
							this.transaction.booking
						).format('YYYY-MM-DDTHH:mm');
					});
			});
		},
		methods: {
			editTransaction() {
				this.transaction.account_id = this.$route.params.account_id;
				this.transaction.booking = moment(
					this.transaction.booking
				).format('YYYY-MM-DD HH:mm:ss');

				const transactionsStore = useTransactionsStore();
				transactionsStore
					.editTransaction({
						accountId: this.transaction.account_id,
						transactionId: this.transaction.id,
						transactionData: this.transaction,
					})
					.then((response) => {
						this.$router.push({
							name: 'AccountsView',
							params: { id: response.account_id },
						});
					});
			},
		},
	};
</script>

<style></style>
