<template>
	<p>Customer Edit</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="article.number" type="text"
						>Articlenumber</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="article.title" type="text">Title</w-input>
				</div>
				<div class="mb-4">
					<w-input v-model="article.ek" type="number">EK</w-input>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="article.vat" type="number">Vat</w-input>
				</div>
				<div class="mb-4">
					<w-input v-model="article.manufactor" type="text"
						>Manufactor</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="article.vk" type="number">VK</w-input>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-textarea v-model="article.description"
						>Description</w-textarea
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-textarea v-model="article.supplierinfo"
						>Supplierinfo</w-textarea
					>
				</div>
			</div>
		</div>
		<w-card>
			<div class="flex justify-evenly">
				<w-checkbox v-model="article.active">Active</w-checkbox>
				<w-checkbox v-model="article.camparticle"
					>Camparticle</w-checkbox
				>
				<w-checkbox v-model="article.service">Service</w-checkbox>
				<w-checkbox v-model="article.toproduction"
					>To Production</w-checkbox
				>
				<w-checkbox v-model="article.saleitem">Saleitem</w-checkbox>
			</div>
		</w-card>
	</div>
	<div class="container mx-auto">
		<div class="m-4">
			<w-btn
				@click="this.editArticle()"
				:disabled="button.disabled"
				:loading="button.loading"
				>Bearbeiten</w-btn
			>
		</div>
	</div>
</template>

<script>
	import {
		WInput,
		WTextarea,
		WCheckbox,
		WCard,
		WBtn,
	} from '@wirecore/wirecomponents';
	import { useArticlesStore } from './../../store/articles';

	export default {
		name: 'ArticlesEdit',
		components: {
			WBtn,
			WCheckbox,
			WCard,
			WInput,
			WTextarea,
		},
		data() {
			return {
				button: {
					loading: false,
					disabled: false,
				},
				articleId: null,
				article: {
					id: '',
					number: '',
					active: '',
					description: '',
					vk: '',
					ek: '',
					vat: '',
					manufactor: '',
					camparticle: '',
					saleitem: '',
					service: '',
					supplierinfo: '',
					toproduction: '',
				},
			};
		},
		mounted() {
			const articleId = this.$route.params.id;
			this.articleId = articleId;

			const articlesStore = useArticlesStore();
			articlesStore.getArticle(articleId).then((response) => {
				this.article = response;
			});
		},
		methods: {
			editArticle() {
				this.button.disabled = true;
				this.button.loading = true;

				const articlesStore = useArticlesStore();
				articlesStore
					.editArticle({
						articleId: this.articleId,
						articleData: this.article,
					})
					.then((response) => {
						this.button.disabled = false;
						this.button.loading = false;

						if (response != null) {
							this.$router.push({ name: 'ArticlesIndex' });
						}
					});
			},
		},
	};
</script>

<style></style>
