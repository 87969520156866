import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import './assets/tailwind.css';

import plugin from '@wirecore/wirecomponents';
import '@wirecore/wirecomponents/dist/wirecomponents.css';

import { createPinia } from 'pinia';
const pinia = createPinia();
import { useAuthStore } from './store/auth';

router.beforeEach(() => {
	// check url for jwt
	let currentUrl = window.location.href;
	let url = new URL(currentUrl);
	let jwt = url.searchParams.get('jwt');

	if (jwt != null) {
		const authStore = useAuthStore();
		authStore.setJwtToken(jwt);
	}
});

window.addEventListener('message', (event) => {
	if (event.origin === process.env.VUE_APP_MANAGERPANEL_CORE_FRONTEND_URL) {
		if (event.data.jwt != null) {
			const authStore = useAuthStore();
			authStore.setJwtToken(event.data.jwt);
		}
	}
});

createApp(App)
	.use(pinia)
	.use(Toast)
	.use(router)
	.use(VueAxios, axios)
	.use(plugin)
	.mount('#app');
