<template>
	<p>Assignment View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.type"
						type="text"
						disabled
						>Customer Type</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.salutation"
						type="text"
						disabled
						>Customer Salutation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.firstname"
						type="text"
						disabled
						>Customer Firstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.lastname"
						type="text"
						disabled
						>Customer Lastname</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.organisation"
						type="text"
						disabled
						>Customerorganisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedAddresse.street"
						type="text"
						disabled
						>Customer Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.plz" type="text" disabled
						>Customer PLZ</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedAddresse.city"
						type="text"
						disabled
						>Customer City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedAddresse.country"
						type="text"
						disabled
						>Customer Country</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<w-table
			:headers="[
				{ text: 'Title', value: 'articlename' },
				{ text: 'Count', value: 'count' },
				{ text: 'VK', value: 'price' },
				{ text: 'EK', value: 'ek' },
				{ text: 'Vat', value: 'vat' },
				{ text: 'Discount', value: 'discount' },
				{ text: 'Positionswert (Netto)', value: 'netto' },
			]"
			:items="assignmentPositions"
		/>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Einmalig', value: 'unique' },
							{ name: 'Auf Rate', value: 'rate' },
						]"
						v-model="assignment.paymenttype"
						disabled
						>Payment Type</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="assignment.ratecount" type="text" disabled
						>Ratecount</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Täglich', value: 'daily' },
							{ name: 'Wöchentlich', value: 'weekly' },
							{ name: 'Monatlich', value: 'monthly' },
							{ name: 'Quatal', value: 'quatal' },
							{ name: 'Jährlich', value: 'yearly' },
						]"
						v-model="assignment.ratecycle"
						disabled
						>Ratecycle</w-select
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="assignment.number" type="text" disabled
						>Assignment Number</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="assignment.starttime"
						disabled
						>Starttime</w-datepicker
					>
				</div>
			</div>
			<div class="mb-4">
				<w-select
					:options="[
						{ name: 'Zu erledigen', value: 'todo' },
						{ name: 'Wird erledigt', value: 'bedo' },
						{ name: 'Erledigt', value: 'finished' },
					]"
					v-model="assignment.status"
					disabled
					>Status</w-select
				>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="assignment.endtime"
						disabled
						>Endtime</w-datepicker
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea v-model="assignment.comments">Comments</w-textarea>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-btn @click="this.genInvoice()">Rechnung erstellen</w-btn>
			<br /><br />
			<w-btn @click="this.getDeliveryNote()">Lieferschein drucken</w-btn>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-card headline="Documents">
				<w-btn
					v-for="document in this.documents"
					v-bind:key="document.id"
					@click="this.downloadDocument(document)"
					>{{ document.name }}</w-btn
				>
			</w-card>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<label
				class="block text-gray-700 text-sm font-bold mb-2"
				for="username"
			>
				File
			</label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="username"
				type="file"
				@change="fileInputHandler"
				:value="documentInput"
			/>
			<w-input v-model="documentUploadName" type="text" disabled
				>Name</w-input
			>
			<div class="m-4">
				<w-btn @click="this.uploadDocument()">Dokument hochladen</w-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	import {
		WInput,
		WBtn,
		WTable,
		WSelect,
		WTextarea,
		WDatepicker,
		WCard,
	} from '@wirecore/wirecomponents';
	import { useAssignmentsStore } from './../../store/assignments';

	export default {
		name: 'AssignmentsView',
		components: {
			WBtn,
			WCard,
			WInput,
			WTable,
			WSelect,
			WTextarea,
			WDatepicker,
		},
		mounted() {
			this.$nextTick(function () {
				const assignmentId = this.$route.params.id;

				const assignmentsStore = useAssignmentsStore();
				assignmentsStore
					.getAssignment(assignmentId)
					.then((response) => {
						this.assignment = response;

						// convert start and end time to values that are can showing in fields
						this.assignment.starttime = moment(
							this.assignment.starttime
						).format('YYYY-MM-DDTHH:mm');
						this.assignment.endtime = moment(
							this.assignment.endtime
						).format('YYYY-MM-DDTHH:mm');

						this.selectedCustomer = this.assignment.Customer;
						this.selectedAddresse = this.assignment.Address;
						this.assignmentPositions =
							this.assignment.Assignmentpositions;
					});

				assignmentsStore
					.getAssignmentDocuments(assignmentId)
					.then((response) => {
						this.documents = response;
					});
			});
		},
		data() {
			return {
				selectedCustomer: {
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					organisation: '',
				},
				selectedAddresse: {
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				assignmentPositions: [],
				assignment: {
					id: '',
					customer_id: '',
					address_id: '',
					invoice_id: '',
					project_id: '',
					starttime: null,
					endtime: null,
					number: '',
					status: '',
					comments: '',
					deliverydate: '',
					ratecount: '',
					ratecycle: '',
					paymenttype: '',
					price: {
						netto: '',
						brutto: '',
						vat: '',
						vat_percent: '',
					},
				},
				documents: [],
				documentFile: '',
				documentUploadFileBase64: '',
				documentUploadName: '',
				positionDialogSelectedItem: null,
				positionDialog: {
					title: '',
					count: '',
					price: '',
					ek: '',
					vat: '',
					discount: '',
				},
			};
		},
		methods: {
			genInvoice() {
				const assignmentsStore = useAssignmentsStore();
				assignmentsStore
					.assignmentGenInvoice({
						assignmentId: this.assignment.id,
					})
					.then((response) => {
						this.$router.push({
							name: 'InvoicesView',
							params: { id: response.id },
						});
					});
			},
			getDeliveryNote() {
				const assignmentsStore = useAssignmentsStore();
				assignmentsStore
					.getAssignmentDeliveryNote(this.assignment.id)
					.then(async function (response) {
						const base64Response = await fetch(
							`data:application/pdf;base64,${response.file}`
						);
						const blob = await base64Response.blob();
						const fileURL = URL.createObjectURL(blob);
						// window.open(fileURL) -> opens the blod file in new tab -> blocked by chrome bacause popup
						const fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.download = 'Delivery Note';
						fileLink.click();
					});
			},
			async downloadDocument(documentFile) {
				const base64Response = await fetch(
					`data:application/pdf;base64,${documentFile.file}`
				);
				const blob = await base64Response.blob();
				const fileURL = URL.createObjectURL(blob);
				// window.open(fileURL) -> opens the blod file in new tab -> blocked by chrome bacause popup
				const fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.download = 'Delivery Note';
				fileLink.click();
			},
			fileInputHandler(event) {
				//Read File
				var selectedFile = event.target.files;
				//Check File is not Empty
				if (selectedFile.length > 0) {
					// Select the very first file from list
					var fileToLoad = selectedFile[0];
					// FileReader function for read the file.
					var fileReader = new FileReader();
					var base64;
					// Onload of file read the file content
					let that = this;
					fileReader.onload = function (fileLoadedEvent) {
						base64 = fileLoadedEvent.target.result;
						// Print data in console
						that.documentUploadFileBase64 = base64.split(',')[1];
					};
					// Convert data to base64
					fileReader.readAsDataURL(fileToLoad);
				}
			},
			uploadDocument() {
				const assignmentId = this.$route.params.id;
				const documentData = {
					file: this.documentUploadFileBase64,
					name: this.documentUploadName,
				};

				let that = this;

				const assignmentsStore = useAssignmentsStore();
				assignmentsStore
					.addAssignmentDocument({
						assignmentId: assignmentId,
						documentData: documentData,
					})
					.then((response) => {
						that.documents.push(response);
					});
			},
		},
	};
</script>

<style></style>
