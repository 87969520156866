<template>
	<p>Contracts View</p>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.title" type="text" disabled
						>Title</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="contract.begin"
						disabled
						>Vertragsbeginn</w-datepicker
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.noticeperiodcount" type="text"
						>Kündigungsfrist Tage</w-input
					>
					<w-select
						:options="[
							{ name: 'Tage', value: 'daily' },
							{ name: 'Wochen', value: 'weekly' },
							{ name: 'Monate', value: 'monthly' },
							{ name: 'Quatale', value: 'quatal' },
							{ name: 'Jahre', value: 'yearly' },
						]"
						v-model="contract.noticeperiodcycle"
						disabled
						>Kündigungsfrist</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="contract.autorenewalcount"
						type="text"
						disabled
						>Automatische Verlängerung Tage</w-input
					>
					<w-select
						:options="[
							{ name: 'Tage', value: 'daily' },
							{ name: 'Wochen', value: 'weekly' },
							{ name: 'Monate', value: 'monthly' },
							{ name: 'Quatale', value: 'quatal' },
							{ name: 'Jahre', value: 'yearly' },
						]"
						v-model="contract.autorenewalcycle"
						disabled
						>Automatische Verlängerung</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="contract.mintermcount"
						type="text"
						disabled
						>Mindestlaufzeit Tage</w-input
					>
					<w-select
						:options="[
							{ name: 'Tage', value: 'daily' },
							{ name: 'Wochen', value: 'weekly' },
							{ name: 'Monate', value: 'monthly' },
							{ name: 'Quatale', value: 'quatal' },
							{ name: 'Jahre', value: 'yearly' },
						]"
						v-model="contract.mintermcycle"
						disabled
						>Mindestlaufzeit Zyklus</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-checkbox v-model="contract.permanent" disabled
						>Unbefristet</w-checkbox
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-2">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.type"
						type="text"
						disabled
						>Customer Type</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.salutation"
						type="text"
						disabled
						>Customer Salutation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.firstname"
						type="text"
						disabled
						>Customer Firstname</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.lastname"
						type="text"
						disabled
						>Customer Lastname</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input
						v-model="selectedCustomer.organisation"
						type="text"
						disabled
						>Customerorganisation</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedAddresse.street"
						type="text"
						disabled
						>Customer Street</w-input
					>
				</div>
				<div class="mb-4">
					<w-input v-model="selectedAddresse.plz" type="text" disabled
						>Customer PLZ</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedAddresse.city"
						type="text"
						disabled
						>Customer City</w-input
					>
				</div>
				<div class="mb-4">
					<w-input
						v-model="selectedAddresse.country"
						type="text"
						disabled
						>Customer Country</w-input
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<w-table
			:headers="[
				{ text: 'Title', value: 'articlename' },
				{ text: 'Count', value: 'count' },
				{ text: 'VK', value: 'price' },
				{ text: 'EK', value: 'ek' },
				{ text: 'Vat', value: 'vat' },
				{ text: 'Discount', value: 'discount' },
				{ text: 'Positionswert (Netto)', value: 'netto' },
			]"
			:items="contractPositions"
		/>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.number" type="text" disabled
						>Number</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-datepicker
						format="YYYY-MM-DD"
						v-model="contract.conclusion"
						disabled
						>Vertragsabschluss</w-datepicker
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Aktiv', value: 'active' },
							{ name: 'Inaktiv', value: 'inactive' },
						]"
						v-model="contract.status"
						disabled
						>Status</w-select
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="grid grid-cols-3">
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[{ name: 'Überweisung', value: 'invoice' }]"
						v-model="contract.paymentmethod"
						disabled
						>Paymentmethod</w-select
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-input v-model="contract.settlement" type="text" disabled
						>Abrechnungstag</w-input
					>
				</div>
			</div>
			<div class="container mx-auto">
				<div class="mb-4">
					<w-select
						:options="[
							{ name: 'Täglich', value: 'daily' },
							{ name: 'Wöchentlich', value: 'weekly' },
							{ name: 'Monatlich', value: 'monthly' },
							{ name: 'Quatal', value: 'quatal' },
							{ name: 'Jährlich', value: 'yearly' },
						]"
						v-model="contract.settlementcycle"
						disabled
						>Abrechnungszyklus</w-select
					>
				</div>
			</div>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-textarea v-model="contract.comments" disabled
				>Comments</w-textarea
			>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<w-card headline="Documents">
				<w-btn
					v-for="document in this.documents"
					v-bind:key="document.id"
					@click="this.downloadDocument(document)"
					>{{ document.name }}</w-btn
				>
			</w-card>
		</div>
	</div>
	<div class="container mx-auto">
		<div class="mb-4">
			<label
				class="block text-gray-700 text-sm font-bold mb-2"
				for="username"
			>
				File
			</label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="username"
				type="file"
				@change="fileInputHandler"
				:value="documentInput"
			/>
			<w-input v-model="documentUploadName" type="text">Name</w-input>
			<div class="m-4">
				<w-btn @click="this.uploadDocument()">Dokument hochladen</w-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	import {
		WInput,
		WBtn,
		WTable,
		WSelect,
		WTextarea,
		WDatepicker,
		WCard,
		WCheckbox,
	} from '@wirecore/wirecomponents';
	import { useContractsStore } from './../../store/contracts';
	import { useCustomersStore } from './../../store/customers';

	export default {
		name: 'ContractsView',
		components: {
			WBtn,
			WCard,
			WInput,
			WTable,
			WSelect,
			WTextarea,
			WDatepicker,
			WCheckbox,
		},
		mounted() {
			this.$nextTick(function () {
				const contractId = this.$route.params.id;

				const contractsStore = useContractsStore();
				contractsStore.getContract(contractId).then((response) => {
					this.contract = response;

					// convert start and end time to values that are can showing in fields
					this.contract.conclusion = moment(
						this.contract.conclusion
					).format('YYYY-MM-DDTHH:mm');

					this.selectedCustomer = this.contract.Customer;
					this.selectedAddresse = this.contract.Address;
					this.contractPositions = this.contract.Contractpositions;
				});

				contractsStore
					.getContractDocuments(contractId)
					.then((response) => {
						this.documents = response;
					});
			});
		},
		data() {
			return {
				customerShowedColumns: ['number', 'name', 'organisation'],
				customerActions: [
					{
						text: 'Select',
						emitName: 'setCustomer',
					},
				],
				customerHeaders: [
					{
						text: 'Number',
						value: 'number',
					},
					{
						text: 'Name',
						value: 'lastname',
					},
					{
						text: 'organisation',
						value: 'organisation',
					},
				],
				customers: [],
				selectedCustomer: {
					type: '',
					salutation: '',
					firstname: '',
					lastname: '',
					organisation: '',
				},
				selectedAddresse: {
					street: '',
					plz: '',
					city: '',
					country: '',
				},
				contract: {
					customer_id: '',
					address_id: '',
					begin: '',
					end: null,
					title: '',
					mintermcount: null,
					mintermcycle: null,
					permanent: '',
					is_template: null,
					noticeperiodcount: null,
					noticeperiodcycle: null,
					autorenewalcount: null,
					autorenewalcycle: null,
					conclusion: null,
					settlement: null,
					comments: '',
					number: null,
					status: '',
					paymentmethod: '',
					settlementcycle: null,
					termination: null,
				},
				contractPositions: [],
				positionDialogSelectedItem: null,
				positionDialog: {
					title: '',
					count: '',
					price: '',
					ek: '',
					vat: '',
					discount: '',
				},
				documents: [],
				documentFile: '',
				documentUploadFileBase64: '',
				documentUploadName: '',
			};
		},
		methods: {
			handleCustomerSelect(data) {
				this.contract.customer_id = data.id;

				const customersStore = useCustomersStore();
				customersStore
					.getCustomer(this.contract.customer_id)
					.then((response) => {
						this.selectedCustomer = response;
						this.contract.address_id = response.Addresses[0].id;
						this.selectedAddresse = response.Addresses[0];
						this.$refs.customerDialog.hide();
					});
			},
			openCustomerDialog() {
				this.$refs.customerDialog.show();
			},
			addContract() {
				const contractsStore = useContractsStore();
				contractsStore.addContract(this.contract).then((response) => {
					const contractId = response.id;

					// add positions
					this.contractPositions.forEach((position) => {
						contractsStore.addContractPosition({
							positionData: position,
							contractId: contractId,
						});
					});

					this.$router.push({ name: 'ContractsIndex' });
				});
			},
			openPositionDialog() {
				// reset dialog data
				this.positionDialog.articlename = '';
				this.positionDialog.count = '';
				this.positionDialog.price = '';
				this.positionDialog.ek = '';
				this.positionDialog.vat = '';
				this.positionDialog.discount = '';
				this.positionDialogSelectedItem = null;

				this.$refs.positionDialog.show();
			},
			savePosition() {
				if (this.positionDialogSelectedItem == null) {
					this.contractPositions.push({
						articlename: this.positionDialog.articlename,
						count: this.positionDialog.count,
						price: this.positionDialog.price,
						ek: this.positionDialog.ek,
						vat: this.positionDialog.vat,
						discount: this.positionDialog.discount,
					});
				} else {
					this.positionDialogSelectedItem.articlename =
						this.positionDialog.articlename;
					this.positionDialogSelectedItem.count =
						this.positionDialog.count;
					this.positionDialogSelectedItem.price =
						this.positionDialog.price;
					this.positionDialogSelectedItem.ek = this.positionDialog.ek;
					this.positionDialogSelectedItem.vat =
						this.positionDialog.vat;
					this.positionDialogSelectedItem.discount =
						this.positionDialog.discount;
				}

				this.$refs.positionDialog.hide();
			},
			editPosition(position) {
				this.positionDialogSelectedItem = position;
				this.positionDialog.articlename =
					this.positionDialogSelectedItem.articlename;
				this.positionDialog.count =
					this.positionDialogSelectedItem.count;
				this.positionDialog.price =
					this.positionDialogSelectedItem.price;
				this.positionDialog.ek = this.positionDialogSelectedItem.ek;
				this.positionDialog.vat = this.positionDialogSelectedItem.vat;
				this.positionDialog.discount =
					this.positionDialogSelectedItem.discount;
				this.$refs.positionDialog.show();
			},
			deletePosition(index) {
				this.contractPositions.splice(index, 1);
			},
			downloadDocument(documentFile) {
				const byteString = Buffer.from(documentFile.file, 'base64');
				const blob = new Blob([byteString], {
					type: 'application/pdf',
				});
				const fileURL = URL.createObjectURL(blob);
				// window.open(fileURL) -> opens the blod file in new tab -> blocked by chrome bacause popup
				const fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.download = 'Delivery Note';
				fileLink.click();
			},
			fileInputHandler(event) {
				//Read File
				var selectedFile = event.target.files;
				//Check File is not Empty
				if (selectedFile.length > 0) {
					// Select the very first file from list
					var fileToLoad = selectedFile[0];
					// FileReader function for read the file.
					var fileReader = new FileReader();
					var base64;
					// Onload of file read the file content
					let that = this;
					fileReader.onload = function (fileLoadedEvent) {
						base64 = fileLoadedEvent.target.result;
						// Print data in console
						that.documentUploadFileBase64 = base64.split(',')[1];
					};
					// Convert data to base64
					fileReader.readAsDataURL(fileToLoad);
				}
			},
			uploadDocument() {
				const contractId = this.$route.params.id;
				const documentData = {
					file: this.documentUploadFileBase64,
					name: this.documentUploadName,
				};

				let that = this;

				const contractsStore = useContractsStore();
				contractsStore
					.addContractDocument({
						contractId: contractId,
						documentData: documentData,
					})
					.then((response) => {
						that.documents.push(response);
					});
			},
		},
	};
</script>

<style></style>
